/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { request } from '.';
import { RequestParams } from './http-client';

import { Errors, GetJsonLdParams, VehicleJsonLd } from './data-contracts';
// api

/**
 * @description Provides various data for the requested model-series in JSON-LD format.
 *
 * @tags 3 - SEO
 * @name GetJsonLd
 * @summary Endpoint for vehicle data in JSON-LD format.
 * @request GET:/api/v1/seo/country/{country}/language/{language}/model-series/{model-series}/jsonld
 */
export const getJsonLd = ({ country, language, modelSeries, ...query }: GetJsonLdParams, params: RequestParams = {}) =>
  request<VehicleJsonLd, Errors>({
    path: `/api/v1/seo/country/${country}/language/${language}/model-series/${modelSeries}/jsonld`,
    method: 'GET',
    query: query,
    format: 'json',
    ...params,
  });
