import { debounceIdle } from '@dh-io-owpi/shared/src/utils/fn.util';
import { ref, Ref, computed, ImgHTMLAttributes } from 'vue';
import type { MediaQuery } from '@workbench/core';
import { wbBreakpoints, currentMediaQuery } from '@dh-io-owpi/shared/src/composables/useMediaQuery';
import { mkBBDImgUrl } from '../utils/bbdImgUrls';

const bpList = Object.keys(wbBreakpoints) as MediaQuery[];

const IMG_SIZE = 1920;

/**
 * Returns the width of the upper breakpoint
 * When the window is resized up it will change the size, but ignore it if it is smaller
 */
export function useResponsiveImage(): Ref<number> {
  const prevSize = ref(0);

  const debounced = debounceIdle((v: number) => (prevSize.value = v));

  return computed(() => {
    const mq = currentMediaQuery.value;
    const nextMQ = bpList[bpList.indexOf(mq) + 1];
    const nextSize = nextMQ ? wbBreakpoints[nextMQ] : IMG_SIZE;

    // In the first run we always set the prevSize
    if (!prevSize.value) prevSize.value = nextSize;
    // Ignore if the next size is smaller
    else if (nextSize > prevSize.value) debounced(nextSize);

    return prevSize.value;
  });
}

export function mkBBDResponsiveParams(baseImageUrl: string, perspective: number): ImgHTMLAttributes {
  return {
    src: mkBBDImgUrl(baseImageUrl, perspective),
    srcset: bpList
      .map((mq) => {
        const nextMQ = bpList[bpList.indexOf(mq) + 1];
        const s = nextMQ ? wbBreakpoints[nextMQ] : IMG_SIZE;
        return `${mkBBDImgUrl(baseImageUrl, perspective, s)} ${s}w`;
      })
      .join(', '),
  };
}
