import type { SharedAemData } from '../types/aem';
import { getJsonLd } from '@dh-io-owpi/backend-api/src/Seo';
import { VehicleJsonLd } from '@dh-io-owpi/backend-api/src/data-contracts';
import { sendApiError } from '@dh-io-owpi/shared/src/services/Error';

/**
 * Load the json+ld from the API using the AEM data and add it to the head
 */
export function useJsonLd(aemData: SharedAemData, userStyleId?: string | undefined) {
  if (!aemData.isAemEditMode) {
    loadJsonLd(aemData, userStyleId).then((data) => {
      if (!data) return;
      const script = document.createElement('script');
      script.setAttribute('type', 'application/ld+json');
      script.textContent = JSON.stringify(data);
      document.head.appendChild(script);
    });
  }
}

/**
 * Load the json+ld from the API using the AEM data
 */
export function loadJsonLd(
  aemData: SharedAemData,
  userStyleId?: string | undefined,
): Promise<VehicleJsonLd | undefined> {
  return getJsonLd(
    {
      country: aemData.country,
      language: aemData.language,
      modelSeries: aemData.modelSeries,
      'sub-brands': aemData.subBrands,
      'aem-paint-id': aemData.vehicleColorCode,
      'user-style-id': userStyleId,
    },
    { retries: 0 },
  )
    .then(({ data }) => data)
    .catch(sendApiError);
}
