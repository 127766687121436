/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** base url */
  baseUrl?: string;
  /** number of retrys in case the request fails */
  retries?: number;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl'>;
  retries?: number;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> {
  response: Response;
  data: D;
  error: E;
}

/**
 * converts a record of name/value to a query string to be added on a url
 * Values that are null or undefined will be ignored
 * Values that are arrays will be converted to multiple entries
 */
function toQueryString<T>(query?: T | QueryParamsType): string {
  return new URLSearchParams(
    Object.entries(query || {})
      .filter(([, value]) => value != null)
      .flatMap(([name, value]) => (Array.isArray(value) ? value.map((v) => [name, v]) : [[name, value]])),
  ).toString();
}

function mergeRequestParams(p1: RequestParams, p2: RequestParams): RequestParams {
  return {
    ...p1,
    ...p2,
    headers: {
      ...p1.headers,
      ...p2.headers,
    },
  };
}

function waitToReconnect(delay: number): Promise<Response> {
  return new Promise((resolve: any) => setTimeout(resolve, delay));
}

export class HttpClient {
  public baseUrl: string = '';
  public retries = 0;
  private baseApiParams: RequestParams = {};
  private retryDelay = 1000;

  constructor(apiConfig: ApiConfig = {}) {
    Object.assign(this, apiConfig);
  }

  public request = async <T = any, E = any>(fullRequestParams: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const { secure, path, query, format, baseUrl, retries, ...params } = fullRequestParams;
    const requestParams = mergeRequestParams(this.baseApiParams, params);
    const queryString = toQueryString(query);
    const responseFormat = format || requestParams.format;
    const url = `${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`;

    let _retries = retries ?? this.retries ?? 0;
    let _retryDelay = this.retryDelay;

    const _fetch = (): Promise<HttpResponse<T, E>> =>
      fetch(url, requestParams)
        .then(async (response) => {
          const data = !responseFormat
            ? { response, data: null, error: null }
            : await response[responseFormat]()
                .then((data) => ({
                  response,
                  data: response.ok ? data : null,
                  error: !response.ok ? data : null,
                }))
                .catch((error) => ({
                  response,
                  error,
                  data: null,
                }));

          if (!response.ok) throw data;
          return data;
        })
        .catch((error) => {
          _retries--;
          if (error.name === 'AbortError' || _retries <= 0) throw error;

          return waitToReconnect(_retryDelay).then(_fetch);
        });

    return _fetch();
  };
}
