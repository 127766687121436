import { VehiclePrice } from '@dh-io-owpi/backend-api/src/data-contracts';

/**
 * formatPrice formats the price to the correct format from AEM language/country and currency from API response
 */
export function formatPrice(
  { country, language }: { country: string; language: string },
  { currency, price }: VehiclePrice,
): string {
  return Intl.NumberFormat(`${language}-${country}`, { style: 'currency', currency }).format(price);
}
