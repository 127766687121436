import type { Environment } from '../types/aem';

export interface Configuration {
  apiBaseUrl: string;
}

const i18nFallbackLocale = 'en';
export const i18nPhraseOptions = `i18next&include_empty_translations=true&fallback_locale_id=${i18nFallbackLocale}`;
export const i18nProjectId = '56e8fc1c95cde3261f163e98a69b67e0';

const prodApiUrl = 'https://api.oneweb.mercedes-benz.com/pi-api';
const nonProdApiUrl = 'https://int.api.oneweb.mercedes-benz.com/pi-api';

const nonProdEnvironments: Environment[] = ['TEST', 'INT'];

export function getConfiguration(env: Environment): Configuration {
  return { apiBaseUrl: nonProdEnvironments.includes(env) ? nonProdApiUrl : prodApiUrl };
}
