import { inject, provide, type Plugin } from 'vue';
import { panic } from '../../utils/panic';
import { AemData } from './types';

export type { Environment, AemData } from './types';

/**
 * Create a plugin that provides the AEM data to the component tree.
 */
export function createAemDataPlugin(aemData: AemData): Plugin {
  return {
    install(app) {
      app.provide<AemData>('aemData', aemData);
    },
  };
}

/**
 * Get the AEM data from the component tree.
 */
export function useAemData<ComponentData>() {
  return inject<AemData<ComponentData>>('aemData') ?? panic('aemData not provided or not initialized');
}

/**
 * Provide the AEM data to the component tree.
 * This function should only be used for tests as aemData is automatically injected by createAemDataPlugin
 */
export function provideAemData<T = unknown>(aemData: AemData<T>) {
  provide<AemData>('aemData', aemData);
}

export type UseAemDataFn<T = unknown> = () => AemData<T>;
