<script lang="ts" setup>
import { computed } from 'vue';
import type { Components } from '@workbench/core';
import type { Style } from '../../lib/api';

const props = defineProps<{
  styles: Style[];
  selectedStyle?: Style;
  switchStyle(style: Style): void;
  direction?: Components.WbButtonGroup['direction'];
  isDark: boolean;
}>();

const selectedStyleIndex = computed(() => props.styles.findIndex((i) => i.id === props.selectedStyle?.id));
</script>
<template>
  <div class="owpi-mbusa-style-switcher-slider">
    <div class="owpi-mbusa-style-switcher-slider__navigation">
      <button
        v-show="selectedStyleIndex !== 0"
        :class="[
          'wb-round-button wb-round-button--solid-level-0 wb-round-button--small owpi-mbusa-style-switcher-slider__navigation-button',
          { 'wb-round-button--theme-dark': isDark },
        ]"
        tabindex="0"
        @click="switchStyle(styles[selectedStyleIndex - 1])"
      >
        <wb-icon name="bds/chevron-left/24" flip-rtl></wb-icon>
      </button>
      <p class="owpi-mbusa-style-switcher-slider__label wb-type-heading-xs">{{ selectedStyle?.name }}</p>

      <button
        v-show="selectedStyleIndex !== styles.length - 1"
        :class="[
          'wb-round-button wb-round-button--solid-level-0 wb-round-button--small owpi-mbusa-style-switcher-slider__navigation-button',
          { 'wb-round-button--theme-dark': isDark },
        ]"
        tabindex="0"
        @click="switchStyle(styles[selectedStyleIndex + 1])"
      >
        <wb-icon name="bds/chevron-right/24" flip-rtl></wb-icon>
      </button>
    </div>
    <wb-dot-navigation
      :pages="styles.length"
      :current-page="selectedStyleIndex + 1"
      prevent-interaction
      :theme="isDark ? 'dark' : 'light'"
    ></wb-dot-navigation>
  </div>
</template>
<style lang="scss">
.owpi-mbusa-style-switcher-slider {
  &__navigation {
    width: 100%;
    display: grid;
    grid-template-columns: 48px 1fr 48px;
    grid-template-areas: 'prev label next';
    align-items: center;
    margin-bottom: var(--wb-spacing-s);
  }

  &__label {
    @extend %ellipsis;
    margin: 0 var(--wb-spacing-s);
    grid-area: label;
    text-align: center;
    color: var(--stage-fg-color);
  }

  &__navigation-button {
    &:first-of-type {
      grid-area: prev;
    }

    &:last-of-type {
      grid-area: next;
      justify-self: end;
    }
  }
}
</style>
