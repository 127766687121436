/**
 * Returns a promise that is resolved when the image is loaded or rejected if the image fails to load.
 */
export const preloadImage = (src: string) =>
  new Promise((onload, onerror) => Object.assign(new Image(), { src, onload, onerror }));

export async function loadImage(imageURL: string, signal?: AbortSignal) {
  const response = await fetch(imageURL, { signal });
  const fileBlob = await response.blob();
  return URL.createObjectURL(fileBlob);
}

/**
 * Returns a promise that is resolved when the image is loaded or rejected if the image fails to load.
 * Receives an existing image element to use and optionally assigns a new src to it.
 */
export const waitForLoad = (img: HTMLImageElement, src?: string) =>
  new Promise((resolve, reject) => {
    img.addEventListener('load', resolve, { once: true });
    img.addEventListener('error', reject, { once: true });
    if (src) img.src = src;
  });

import type { Components } from '@workbench/core';

type ImageConfig = Components.Wb360Viewer['images'][0];

// Fallback mechanism: if we receive errors on some images, we set the images again with cache kill
export function cacheKill(r: PromiseSettledResult<unknown>[], images: ImageConfig[], im: 'highRes' | 'lowRes') {
  r.forEach((p, i) => {
    if (p.status === 'rejected') images[i][im]!.src += '&ck=' + Date.now();
  });
}
