import { Ref, computed, ref, watch } from 'vue';
import { useLoading } from '@dh-io-owpi/shared/src/composables/useLoading';
import { ValidationError } from '@dh-io-owpi/backend-api/src/data-contracts';

import type { AemData } from '../lib/types/aem';
import type VehicleInformation from 'src/lib/types/damJSONData';
import { getVehicle, getModelSeries, getPaints } from '../lib/api';
import type { StageVehicle, Paint, Style } from '../lib/api';
import { ValidationNotification } from '@dh-io-owpi/shared/src/components/ErrorList/ValidationNotification';
import { sendApiError } from '@dh-io-owpi/shared/src/services/Error';
import { parseVehicleInformation, getVehiclesInformation } from '../utils/utils';

interface StageOptions {
  stageVehicle: Ref<StageVehicle | undefined>;
  stagePaints: Ref<Paint[] | undefined>;
  stageStyles: Ref<Style[] | undefined>;
  errorMessage: Ref<string | undefined>;
  validationErrors: Ref<ValidationNotification[]>;
  selectedColor: Ref<Paint | undefined>;
  selectedStyle: Ref<Style | undefined>;
  switchColor(color: Paint): void;
  switchStyle(style: Style): void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStage = (aemData: AemData): StageOptions => {
  const stageVehicle = ref<StageVehicle | undefined>();
  const stageStyles = ref<Style[]>();
  const stagePaints = ref<Paint[]>();
  const selectedPaintId = ref<string>();
  const selectedStyleId = ref<string>();
  const errorMessage = ref<string>();
  const validationErrors = ref<ValidationNotification[]>([]);

  const { load } = useLoading('stage');

  // add errors to the validationErrors array, discarding repeated errors
  const errorCodes = new Set<string>();
  const _concatErrors = (errors: ValidationError[] | null | undefined) => {
    validationErrors.value = validationErrors.value.concat(
      errors?.flatMap(({ key, aemAuthorMessage, severity }) => {
        const message = `${key}: ${aemAuthorMessage}`;
        if (errorCodes.has(message)) return [];
        errorCodes.add(message);
        return [{ mode: severity == 'ERROR' ? 'error' : 'warning', message }];
      }) ?? [],
    );
  };

  watch(selectedStyleId, (styleId, oldStyleId) => {
    if (styleId === oldStyleId) return;
    return getPaints({
      country: aemData.country,
      language: aemData.language,
      modelSeries: aemData.modelSeries,
      'sub-brands': aemData.subBrands,
      'aem-paint-id': aemData.vehicleColorCode,
      'promoted-paint-ids': aemData.promotedPaints?.map((promotedPaint) => promotedPaint.code),
      'max-paints': 20,
      'user-style-id': styleId,
    }).then(
      (d) => {
        stagePaints.value = d.data.paints;
        _concatErrors(d.data.errors);
      },
      (e) => {
        errorMessage.value = e.error?.errors?.[0]?.message ?? e.error;
        sendApiError(e);
      },
    );
  });
  load(
    getModelSeries({
      country: aemData.country,
      language: aemData.language,
      modelSeries: aemData.modelSeries,
      'brand-ids': aemData.subBrands?.length ? aemData.subBrands : ['MERCEDES_BENZ'],
      'author-mode': aemData.isAemEditMode,
    }).then(
      (d) => {
        stageStyles.value = d.data.styles;
        // select the first style to solve issues with IT where the default style may not be in the style list
        selectedStyleId.value = d.data.styles[0]?.id;
        _concatErrors(d.data.errors);
      },
      (e) => {
        sendApiError(e);
        errorMessage.value = e.error?.errors?.[0]?.message ?? e.error;
      },
    ),
  );

  let vehicleRequestController: AbortController | null;

  watch(
    [selectedStyleId, selectedPaintId],
    ([userStyleId, userPaintId], [, oldUserPaintId]) => {
      const vehicle = stageVehicle.value;

      // wait for having the styles to be loaded, as in IT the default style may not be in the style list
      if (!userStyleId) return;

      // ignore if the selected style/paint is the same as the previous loaded vehicle
      if (vehicle && vehicle.currentSelection.styleId == userStyleId && vehicle.currentSelection.paintId == userPaintId)
        return;

      if (vehicleRequestController) vehicleRequestController.abort();

      vehicleRequestController = new AbortController();

      load(
        getVehicle(
          {
            country: aemData.country,
            language: aemData.language,
            modelSeries: aemData.modelSeries,
            'sub-brands': aemData.subBrands,
            'aem-paint-id': aemData.vehicleColorCode,
            'aem-equipment-ids': aemData.featureElements?.map((featureElement) => featureElement.code),
            'user-paint-id': userPaintId,
            'user-style-id': userStyleId,
            'author-mode': aemData.isAemEditMode,
          },
          { retries: 3, signal: vehicleRequestController.signal },
        ).then(
          (d) => {
            let dynamicStageModelObject: Record<string, VehicleInformation> = {};
            if (Object.keys(dynamicStageModelObject).length === 0) {
              // get vehicle data from dam
              getVehiclesInformation()
                .then((jsonObject) => {
                  dynamicStageModelObject = parseVehicleInformation(jsonObject);

                  if (dynamicStageModelObject[aemData.modelSeries]) {
                    d.data.modelName = dynamicStageModelObject[aemData.modelSeries].modelName;
                  }

                  const isSelectedColorInvalid = d.data.errors?.find((e) => e.key === selectedPaintId.value);
                  // We want to select the last chosen color when the selected color is not available.
                  // but only if it is actually an error and not just a info message
                  if (isSelectedColorInvalid && isSelectedColorInvalid.severity !== 'INFO') {
                    selectedPaintId.value = oldUserPaintId;
                  } else {
                    stageVehicle.value = d.data;
                  }

                  _concatErrors(d.data.errors);
                })
                .catch((error) => {
                  console.error(error);
                });
            }
          },
          (e) => {
            sendApiError(e);
            errorMessage.value = e.error?.errors?.[0]?.message ?? e.error;
          },
        ),
      );
    },
    { immediate: true },
  );

  /**
   * Set the selectedPaintId as soon as we have the paints and the vehicle data
   */
  watch([stageStyles, stageVehicle], ([styles, vehicle]) => {
    if (styles && vehicle) {
      const { paintId, styleId } = vehicle.currentSelection;

      selectedStyleId.value = styleId;
      selectedPaintId.value = paintId;
    }
  });

  const selectedColor = computed(() => stagePaints.value?.find((paint) => paint.id === selectedPaintId.value));
  const selectedStyle = computed(() => stageStyles.value?.find((style) => style.id === selectedStyleId.value));

  function switchColor(color: Paint): void {
    selectedPaintId.value = color.id;
  }
  function switchStyle(style: Style): void {
    selectedStyleId.value = style.id;
  }

  return {
    stageVehicle,
    stagePaints,
    stageStyles,
    errorMessage,
    validationErrors,
    selectedColor,
    switchColor,
    selectedStyle,
    switchStyle,
  };
};
