/**
 * Very minimal store implementation.
 * It mimics the API of pinia, but with a lot less features.
 * It may be replaced by pinia in the future.
 * The reason why it's not pinia is that it's not compatible with Vue 2 UMD bundle.
 */
export function defineStore<T extends Store>(name: string, setup: () => T): () => T {
  let store: T;
  return () => {
    if (store) return store;
    store = setup();
    stores[name] = store;
    return store;
  };
}

type Store = {
  [key: string]: unknown;
};

const stores: Record<string, Store> = {};
