<script lang="ts" setup>
import { computed, ref, onMounted } from 'vue';

import { vTransition } from '@dh-io-owpi/shared/src/directives/v-transition';
import { preloadImage } from '@dh-io-owpi/shared/src/utils/img.util';

import shadeLight from '@dh-io-owpi/shared-dynamic-stage/assets/Stage_background_light.svg';
import shadeDark from '@dh-io-owpi/shared-dynamic-stage/assets/Stage_background_dark.svg';
import patternStar from '@dh-io-owpi/shared-dynamic-stage/assets/MB_pattern_stage_black_gradient_min_df67872770.png';
import patternAMG from '@dh-io-owpi/shared-dynamic-stage/assets/AMG_pattern_stage_black_gradient_fade_57c8cd146d.png';

const props = defineProps<{
  isDark: boolean;
  campaignBg?: string | null;
}>();

const isReady = ref(false);

const preloadBackground = async () => {
  if (props.campaignBg) {
    await preloadImage(props.campaignBg);
  } else if (props.isDark) {
    await Promise.all([preloadImage(shadeDark), preloadImage(patternAMG)]);
  } else {
    await Promise.all([preloadImage(shadeLight), preloadImage(patternStar)]);
  }
};

onMounted(async () => {
  await preloadBackground();
  isReady.value = true;
});

const campaign = computed(() => `url('${props.campaignBg}')`);
const shade = computed(() => `url('${props.isDark ? shadeDark : shadeLight}')`);
const pattern = computed(() => `url('${props.isDark ? patternAMG : patternStar}')`);
</script>
<template>
  <Transition name="crossfade">
    <div v-show="isReady" v-transition="isDark" class="owpi-mbusa-stage-bg" :data-theme="isDark ? 'dark' : 'light'">
      <div v-if="campaignBg" class="owpi-mbusa-stage-bg__campaign"></div>
      <div v-else>
        <div class="owpi-mbusa-stage-bg__shade"></div>
        <div class="owpi-mbusa-stage-bg__pattern"></div>
      </div>
    </div>
  </Transition>
</template>
<style lang="scss" scoped>
@import '../../variables.sass';
.owpi-mbusa-stage-bg {
  // All layers fill the whole stage
  &,
  &__shade,
  &__pattern,
  &__campaign {
    position: absolute;
    inset: 0;
  }

  &[data-transition],
  &[data-transition] &__shade,
  &[data-transition] &__campaign,
  &[data-transition] &__pattern {
    transition: $wb-fade background 0.3s;
  }

  &__shade {
    background-image: v-bind('shade');
    background-repeat: no-repeat;

    background-size: 135%;
    background-position: center calc(-37vw + #{$sales-bar-height});
    @include breakpoint-to(mq2) {
      background-size: 178%;
    }
    @include breakpoint-between(mq3, mq4) {
      background-position: center calc(-34vw + #{$sales-bar-height});
    }
    @include breakpoint-from(mq4) {
      background-position: center -34vw;
    }
    @media (min-width: 1920px) {
      background-size: 2560px;
      background-position: center -630px;
    }
    // degrade the background image on large screens, to avoid sharp edges
    @media (min-width: 2561px) {
      mask: linear-gradient(
          to right,
          transparent 0%,
          rgba(0, 0, 0, 0.9) 4%,
          black 5%,
          black 95%,
          rgba(0, 0, 0, 0.9) 96%,
          transparent 100%
        )
        center;
      mask-size: 2560px 100%;
    }
  }

  &__campaign {
    $max-size: 3000px;
    background-image: v-bind('campaign');

    background-position: center calc(-70vw + #{$sales-bar-height});
    background-size: 170%;
    background-repeat: no-repeat;

    @include breakpoint-from(mq3) {
      background-position: center calc(-78vw + #{$sales-bar-height});
      background-size: 162%;
    }

    @include breakpoint-from(mq4) {
      background-size: 146%;
    }

    @include breakpoint-from(mq6) {
      background-position: center -73vw;
      background-size: 150%;
    }

    @media (min-width: 1920px) {
      background-size: $max-size;
      background-position: center -1470px;
    }

    @media (min-width: $max-size) {
      mask: linear-gradient(
          to right,
          transparent 0%,
          rgba(0, 0, 0, 0.9) 4%,
          black 5%,
          black 95%,
          rgba(0, 0, 0, 0.9) 96%,
          transparent 100%
        )
        center;
      mask-size: $max-size 100%;
    }
  }

  &__pattern {
    background-image: v-bind('pattern');
    background-position: center -10vw;
    background-repeat: no-repeat;
    background-size: 180%;
    // scale the background a bit in all MQs so we can still recognize the pattern
    mask: radial-gradient(rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 50%, transparent 70%) //
      no-repeat center;
    mask-size: 80% 70%;

    // To keep the pattern above the horizont line, we need to change the position for the different MQs
    @include breakpoint-from(mq2) {
      background-position: center -20vw;
      mask-size: 120% 70%;
    }
    @include breakpoint-between(mq3, mq4) {
      background-size: 120%;
      background-position: center -15vw;
      mask-size: 120% 80%;
    }
    @include breakpoint-from(mq4) {
      background-size: 115%;
      background-position: center -17vw;
      max-width: var(--wb-grid-width);
      margin-inline: auto;
      mask-size: 120%;
    }
    @include breakpoint-from(mq6) {
      background-position: center -22vw;
      background-size: 130%;
      mask-size: 100%;
    }
    @media (min-width: 1920px) {
      background-size: 1920px;
      background-position: center -348px;
    }
  }

  &[data-theme='light'] {
    background-color: #fff;

    .owpi-mbusa-stage-bg__pattern {
      filter: invert(0.26) opacity(0.5);
    }
  }

  &[data-theme='dark'] {
    background-color: black;

    .owpi-mbusa-stage-bg__pattern {
      filter: invert(0.998247) opacity(0.3);
    }

    .owpi-mbusa-stage-bg__shade {
      opacity: 0.5;
    }
  }
}

.crossfade {
  &-enter-active,
  &-leave-active {
    transition: opacity 1s ease;
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
}
</style>
