/**
 * converts a record of name/value to a query string to be added on a url
 * Values that are null or undefined will be ignored
 * Values that are arrays will be converted to multiple entries
 */
export function buildQuery<T>(query: { [s: string]: T } | ArrayLike<T>): string {
  return new URLSearchParams(
    Object.entries(query)
      .filter(([, value]) => value != null)
      .flatMap(([name, value]) => (Array.isArray(value) ? value.map((v) => [name, v]) : [[name, value]])),
  ).toString();
}

export function buildQueryParam(color: any, selectedStyleName: any, amgLinePackage: string): string {
  const colorCode = color.split('-')[1];
  let query = `2:${colorCode}`;

  if (selectedStyleName === 'AMG Line') query += `,${amgLinePackage}`;

  return query;
}
