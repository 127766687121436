/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { request } from '.';
import { RequestParams } from './http-client';

import {
  BrandId,
  Errors,
  FeatureCategories,
  GetModelSeriesParams,
  GetVehicleBaseDataParams,
  ModelSeries,
  VehicleBaseData,
  VehicleJsonLd,
} from './data-contracts';
// api

/**
 * @description Provides model series base data (name) and available styles and paints.
 *
 * @tags 4 - SPA
 * @name GetModelSeries
 * @summary Endpoint for model series base data.
 * @request GET:/api/v1/spa/country/{country}/language/{language}/model-series/{model-series}
 */
export const getModelSeries = (
  { country, language, modelSeries, ...query }: GetModelSeriesParams,
  params: RequestParams = {},
) =>
  request<ModelSeries, Errors>({
    path: `/api/v1/spa/country/${country}/language/${language}/model-series/${modelSeries}`,
    method: 'GET',
    query: query,
    format: 'json',
    ...params,
  });
/**
 * @description Provides title, description and image URL for each highlighted feature equipment and returns them in a categorized tree.
 *
 * @tags 4 - SPA
 * @name GetVehicleFeatures
 * @summary Endpoint for vehicle highlight features.
 * @request GET:/api/v1/spa/country/{country}/language/{language}/model-series/{model-series}/brand/{brand-id}/features
 */
export const getVehicleFeatures = (
  country: string,
  language: string,
  modelSeries: string,
  brandId: BrandId,
  params: RequestParams = {},
) =>
  request<FeatureCategories, Errors>({
    path: `/api/v1/spa/country/${country}/language/${language}/model-series/${modelSeries}/brand/${brandId}/features`,
    method: 'GET',
    format: 'json',
    ...params,
  });
/**
 * @description Provides the vehicle name, price, stage image, exterior and interior information for the given vehicle id.
 *
 * @tags 4 - SPA
 * @name GetVehicleBaseData
 * @summary Endpoint for vehicle base data.
 * @request GET:/api/v1/spa/country/{country}/language/{language}/model-series/{model-series}/vehicle/{vehicle-id}/base-data
 */
export const getVehicleBaseData = (
  { country, language, modelSeries, vehicleId, ...query }: GetVehicleBaseDataParams,
  params: RequestParams = {},
) =>
  request<VehicleBaseData, Errors>({
    path: `/api/v1/spa/country/${country}/language/${language}/model-series/${modelSeries}/vehicle/${vehicleId}/base-data`,
    method: 'GET',
    query: query,
    format: 'json',
    ...params,
  });
/**
 * @description Provides various data for the requested model-series in JSON-LD format.
 *
 * @tags 4 - SPA
 * @name GetVehicleSeoData
 * @summary Endpoint for vehicle data in JSON-LD format.
 * @request GET:/api/v1/spa/country/{country}/language/{language}/model-series/{model-series}/vehicle/{vehicle-id}/seo/json-ld
 */
export const getVehicleSeoData = (
  country: string,
  language: string,
  modelSeries: string,
  vehicleId: string,
  params: RequestParams = {},
) =>
  request<VehicleJsonLd, Errors>({
    path: `/api/v1/spa/country/${country}/language/${language}/model-series/${modelSeries}/vehicle/${vehicleId}/seo/json-ld`,
    method: 'GET',
    format: 'json',
    ...params,
  });
