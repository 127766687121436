import { Ref, computed } from 'vue';

export const MAX_TOOLTIP_CHARS = 600;
export const ELLIPSIS = '...';

/**
 * @description Returns tooltip text with a character limit defined in MAX_TOOLTIP_CHARS
 * @param {string|undefined} purchasePriceTooltip text of the tooltip
 * @returns {string|undefined}
 */
export function usePurchasePriceTooltip(purchasePriceTooltip?: string): Ref<string | undefined> {
  return computed(() => {
    if (purchasePriceTooltip && purchasePriceTooltip.length > MAX_TOOLTIP_CHARS) {
      return purchasePriceTooltip.substring(0, MAX_TOOLTIP_CHARS) + ELLIPSIS;
    }
    return purchasePriceTooltip;
  });
}
