import { browser } from '@dh-io-owpi/shared/src/environment';

interface Context {
  [key: string]: unknown;
  errorType: ErrorType;
  traceId?: string;
  appName?: string;
  appVersion?: string;
  tenant?: string;
}

function _sendError(ctx: Context, error: any): undefined {
  const DD_RUM = browser && window.DD_RUM;
  if (DD_RUM) {
    DD_RUM.onReady(() =>
      DD_RUM.addError(error, {
        appName: import.meta.env.APP_NAME,
        appVersion: import.meta.env.APP_VERSION,
        tenant: 'owpi',
        ...ctx,
      }),
    );
  } else {
    // eslint-disable-next-line no-console
    console.error(`[${ctx.errorType} ERROR]`, error);
  }
}

export const sendCodeError = (error: any) => _sendError({ errorType: 'CODE' }, error);
export const sendApiError = (error: any) => {
  const e = error.error ?? error;

  return _sendError({ errorType: 'API', traceId: e?.traceId }, { ...e, url: error.response?.url });
};

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    DD_RUM?: typeof import('@datadog/browser-rum').datadogRum;
  }
}

type ErrorType = 'API' | 'CODE';
