import { ref, Ref } from 'vue';
import {
  getDeeplinks,
  Deeplinks,
  Deeplink,
  GetDeeplinksParams,
  ProductType,
  getHardCodedLinks,
} from './../services/VehicleDeeplinks/index';
import { defineStore } from './store';

export const useVehicleDeepLinksStore = defineStore('pi-vehicleDeepLinks', () => {
  const map = new Map<string, Ref<VehicleDeepLinkState | undefined>>();

  function get(params: GetDeeplinksParams, enableHardCodedLinks?: boolean) {
    const key = `${params.country}-${params.language}-${params.modelSeries}`;
    let _ref = map.get(key);
    if (!_ref) {
      _ref = ref();
      map.set(key, _ref);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      getDeeplinks(params).then((dl) => {
        if (enableHardCodedLinks) {
          const hardCodedLinks = getHardCodedLinks(params);
          if (hardCodedLinks) {
            dl = {
              ...hardCodedLinks,
              ...dl,
            };
          }
        }
        _ref!.value = mapDeepLinks(dl);
        return _ref!.value;
      });
    }

    return _ref;
  }
  return { get };
});

export type VehicleDeepLinkState = Record<ProductType, VehicleDeepLink>;

interface VehicleDeepLink extends Deeplink {
  type: ProductType;
  icon: string;
}

const mapDeepLinks = (deeplinks: Deeplinks) =>
  Object.fromEntries(
    Object.entries(deeplinks).map<[ProductType, VehicleDeepLink]>(([key, value]) => [
      key as ProductType,
      {
        type: key as ProductType,
        url: value.url,
        label: value.label,
        icon: iconMap[key as ProductType],
      },
    ]),
  ) as VehicleDeepLinkState;

const iconMap: Record<ProductType, string> = {
  CONFIGURATOR: 'bds/car-config/24',
  ONLINE_SHOP: 'bds/shopping-cart/24',
  TEST_DRIVE: 'bds/test-drive/24',
  PRODUCT_PAGE: '', //not used
};
