import { removeLastNSegments } from './removeLastNSegments';

/**
 * @description Method used to build a valid car configurator url
 * @param {string} url (ex: https://www.mercedes-benz.it/passengercars/mercedes-benz-cars/car-configurator.html/motorization/CCci/IT/it/A-KLASSE/KOMPAKT-LIMOUSINE)
 * @param {string} vehicleId (ex: de_DE__1770471__AU-001_GC-429_LE-L_LU-817_MJ-803_PC-U62_PS-012#_SA-02B...)
 * @returns car configurator url
 */
export function buildCarConfiguratorUrl(url: string, vehicleId: string): string {
  return `${removeLastNSegments(url, 2).replace('motorization', 'configuration')}/${encodeURIComponent(vehicleId)}`;
}
