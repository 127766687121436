<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { useTracking } from '@dh-io-owpi/shared/src/composables/useTracking';
import { useLoading } from '@dh-io-owpi/shared/src/composables/useLoading';
import { vTransition } from '@dh-io-owpi/shared/src/directives/v-transition';
import { useStageCtas, CtaTypeMap } from '@dh-io-owpi/shared-dynamic-stage/src/composables/useStageCtas';
import { formatPrice } from '@dh-io-owpi/shared-dynamic-stage/src/utils/formatPrice';
import { buildQueryParam } from '@dh-io-owpi/shared-dynamic-stage/src/utils/buildQuery';
import type { AemData } from '@dh-io-owpi/shared/src/plugins/aemData';

import { isSmallVersion } from '../../composables/isSmallVersion';
import type { Paint, StageVehicle, Style } from '../../lib/api';

import { usePinnedSalesBar } from './usePinnedSalesBar';
const { isLoading } = useLoading('stage');

import { parseVehicleInformation, getVehiclesInformation, getAbstractColor } from './../../utils/utils';
import VehicleInformation from 'src/lib/types/damJSONData';

const props = defineProps<{
  stageVehicle?: StageVehicle;
  aemData: AemData;
  isDark: boolean;
  ctas: CtaTypeMap;
  purchasePriceTooltip?: string;
  selectedStyle?: Style;
  selectedColor?: Paint;
}>();

const stageVehicle = ref(props.stageVehicle);
const buildButtonLink = ref('');
const amgLinePackage = ref('');
const viewInventoryLink = ref('');

const { elRef, isPinned } = usePinnedSalesBar();
const ctas = useStageCtas(props.aemData, props.ctas, stageVehicle, true);

const { createTrackOnceFn } = useTracking('salesbar');
const trackTooltipHover = createTrackOnceFn('click', 'price_tooltip', 'feature');

// When changing stageVehicle, only apply it's values after the image is loaded.
// Ensure that we never show a vehicle price that does not correspond to the visible image.
watch([() => props.stageVehicle, isLoading], ([newVehicle, loading], [oldVehicle]) => {
  if (!oldVehicle || !loading) {
    stageVehicle.value = newVehicle;
  }
});

const vehiclePrice = computed(
  () => stageVehicle.value?.vehiclePrice && formatPrice(props.aemData, stageVehicle.value.vehiclePrice),
);

const colorQueryParam = computed(() => {
  let delimiter = viewInventoryLink.value.includes('?') ? '&' : '?';
  return `${delimiter}exteriorColor=${getAbstractColor(props.selectedColor?.name)}`;
});

const queryParam = computed(() =>
  buildQueryParam(props.selectedColor?.id, props.selectedStyle?.name, amgLinePackage.value),
);

let dynamicStageModelObject: Record<string, VehicleInformation> = {};
if (Object.keys(dynamicStageModelObject).length === 0) {
  getVehiclesInformation()
    .then((jsonObject) => {
      dynamicStageModelObject = parseVehicleInformation(jsonObject);
      if (dynamicStageModelObject[props.aemData.modelSeries]) {
        // updating cta links
        buildButtonLink.value = dynamicStageModelObject[props.aemData.modelSeries].buildLink;
        amgLinePackage.value = dynamicStageModelObject[props.aemData.modelSeries].amgLinePackage;
        viewInventoryLink.value = dynamicStageModelObject[props.aemData.modelSeries].viewInventoryLink;
      }
    })
    .catch((error) => {
      console.error(error);
    });
}
</script>

<template>
  <div
    ref="elRef"
    v-transition="isPinned"
    class="owpi-mbusa-sales-bar"
    :class="{
      'owpi-mbusa-sales-bar--is-dark': isDark,
      'owpi-mbusa-sales-bar--is-pinned': isPinned,
      'owpi-mbusa-sales-bar--is-small': isSmallVersion,
    }"
    :style="[isPinned ? { 'z-index': '32' } : { 'z-index': '9' }]"
  >
    <wb-card class="owpi-mbusa-sales-bar__wrapper" :variant="isDark ? 'dark-grey' : 'white'">
      <div class="owpi-mbusa-sales-bar__content">
        <label class="owpi-mbusa-sales-bar__model-title wb-type-label">
          {{ $t('dynamicStage.modelTitle') || '...' }}
        </label>

        <h1 class="owpi-mbusa-sales-bar__model-name wb-type-copy-strong">
          <template v-if="stageVehicle">
            {{ stageVehicle.modelName }}
          </template>
          <wb-skeleton v-else :theme="isDark ? 'dark' : 'light'">&nbsp;</wb-skeleton>
        </h1>
        <label class="owpi-mbusa-sales-bar__price-title wb-type-label">
          {{ $t('dynamicStage.purchasePricePrefix') || '...' }}
        </label>
        <div class="owpi-mbusa-sales-bar__price-value wb-type-copy-strong">
          <template v-if="vehiclePrice">
            <span>{{ vehiclePrice.slice(0, vehiclePrice.indexOf('.')) }}</span>
            <wb-tooltip-host
              v-if="purchasePriceTooltip"
              :position="isSmallVersion || isPinned ? 'bottom' : 'top'"
              @mouseover="trackTooltipHover"
            >
              <wb-icon name="bds/info-circle/24" />
              <wb-tooltip>{{ purchasePriceTooltip }}</wb-tooltip>
            </wb-tooltip-host>
          </template>
          <wb-skeleton v-else :theme="isDark ? 'dark' : 'light'">&nbsp;</wb-skeleton>
        </div>

        <div class="owpi-mbusa-sales-bar__ctas owpi-mbusa-sales-bar__ctas">
          <a
            v-if="ctas.secondary"
            :data-type="ctas.secondary.type"
            :href="buildButtonLink != '' ? buildButtonLink + queryParam : ctas.secondary.url"
            :title="ctas.secondary.text"
            class="wb-button wb-button--secondary wb-button--semi-transparent wb-button--medium"
            :class="{ 'wb-button--icon-only': isSmallVersion, 'wb-button--theme-dark': isDark }"
            @click="(e) => ctas.secondary?.track(e)"
            @auxclick="(e) => ctas.secondary?.track(e)"
          >
            <wb-icon v-if="isSmallVersion" :name="ctas.secondary.icon" class="wb-button__icon" />
            {{ ctas.secondary.text }}
          </a>
          <a
            v-if="ctas.primary"
            :data-type="ctas.primary.type"
            :href="viewInventoryLink != '' ? viewInventoryLink + colorQueryParam : ctas.primary.url"
            :title="ctas.primary.text"
            class="wb-button wb-button--primary wb-button--medium"
            :class="{ 'wb-button--icon-only': isSmallVersion, 'wb-button--theme-dark': isDark }"
            @click="(e) => ctas.primary?.track(e)"
            @auxclick="(e) => ctas.primary?.track(e)"
          >
            <wb-icon v-if="isSmallVersion" :name="ctas.primary.icon" class="wb-button__icon" />
            {{ ctas.primary.text }}
          </a>
        </div>
      </div>
    </wb-card>
  </div>
</template>

<style lang="scss">
@import '../../variables.sass';

.owpi-mbusa-sales-bar {
  $root: &;

  --psb-pb: var(--wb-spacing-xs);
  --psb-c-title: var(--wb-grey-45);
  --psb-c-value: var(--wb-grey-20);
  --z-index: 32; // It should be Lower than Header (33) and Hotspot side panel (34)

  position: relative;
  z-index: var(--z-index);
  height: $sales-bar-height;
  top: -1px;
  margin: 0 0 var(--psb-pb) 0;

  &--is-dark {
    --psb-c-title: white;
    --psb-c-value: white;
  }

  &[data-transition] {
    #{$root}__wrapper,
    #{$root}__content {
      transition:
        width 0.2s $wb-move,
        padding 0.2s $wb-move;
    }
  }

  &__wrapper {
    height: $sales-bar-height;
    display: flex;
    align-items: center;
    z-index: var(--z-index);

    margin-inline: auto;
    width: var(--wb-grid-width);
  }

  &__content {
    grid-column-gap: var(--wb-spacing-xs);
    grid-row-gap: var(--wb-spacing-3xs);

    display: grid;
    align-items: center;
    grid-template-areas: 'model-title price-title ctas' 'model-name price-value ctas';
    grid-template-columns: auto 1fr auto;
    grid-template-rows: min-content;

    margin-inline: auto;
    width: 100%;
    padding-inline: var(--wb-spacing-m);

    @include breakpoint-to(mq3) {
      grid-template-areas: 'model-name model-name ctas' 'price-title price-value ctas';
      grid-column-gap: var(--wb-spacing-xxs);

      #{$root}__model-title {
        display: none;
      }
    }
  }

  &__model-title,
  &__price-title {
    @extend %ellipsis;
    color: var(--psb-c-title);
  }

  &__model-name,
  &__price-value {
    color: var(--psb-c-value);
  }

  &__model-title {
    grid-area: model-title;
  }

  &__model-name {
    grid-area: model-name;
    @extend %ellipsis;

    wb-skeleton {
      min-width: 15ch;
      display: block;
    }
  }

  &__price-title {
    grid-area: price-title;
  }

  &__price-value {
    grid-area: price-value;

    wb-skeleton {
      min-width: 12ch;
    }

    display: flex;
    align-items: center;
    gap: var(--wb-spacing-xxs);

    wb-icon {
      cursor: pointer;
      width: 20px;
      height: 20px;

      &[name*='/'] {
        width: 16px;
        height: 16px;
      }
    }
  }

  &__ctas {
    grid-area: ctas;
    display: flex;
    align-items: center;
    gap: var(--wb-spacing-xxs);
  }

  &--is-small {
    --psb-pb: 0;
  }

  &--is-small,
  &--is-pinned {
    #{$root}__wrapper {
      width: 100%;
    }

    #{$root}__content {
      width: var(--wb-grid-width);
      padding-inline: 0;
    }
  }

  &--is-pinned {
    #{$root}__wrapper {
      position: fixed;
      inset: 0 0 auto 0;
    }
  }

  // avoid displaying the tooltip while workbench is still loading
  wb-tooltip:not(.hydrated) {
    display: none;
  }
}
</style>
