<script lang="ts" setup>
import { computed, inject, ref, watch } from 'vue';
import { useLoading } from '@dh-io-owpi/shared/src/composables/useLoading';
import { currentDirection } from '@dh-io-owpi/shared/src/composables/useDirection';
import type { Paint } from '../../lib/api';
import type { SwitcherKind } from '../../lib/types/switchers';
import { KEY_VEHICLE_TRACKING_DATA, useTracking } from '@dh-io-owpi/shared/src/composables/useTracking';

const props = defineProps<{
  colors: Paint[];
  selectedColor?: Paint;
  isExpanded: boolean;
  isDark: boolean;
}>();
const emit = defineEmits<{
  (event: 'switch', color: Paint): void;
  (event: 'expand', kind: SwitcherKind): void;
}>();
const { isLoading } = useLoading('stage');
const overPaint = ref<Paint>();
const mouseDown = ref(false);

const vehicleTrackingData = inject(KEY_VEHICLE_TRACKING_DATA)!;

const { track } = useTracking('dynamic_stage|color_switcher');

const previouslySelectedColors: string[] = [];

const getColorPosition = (color: Paint): number => {
  return props.colors.findIndex((c) => c.id === color.id) + 1;
};

const switchColor = (color: Paint, e: Event): void => {
  if (!isSelected(color)) {
    // safari doesn't focus the radio button when it's clicked, so we do it manually
    if (document.activeElement !== e.target) (e.target as HTMLInputElement).focus();
    emit('switch', color);

    // only track color selection once
    if (previouslySelectedColors.includes(color.id)) return;

    track('click', `${getColorPosition(color)}|${color.name}.${color.id}`, 'feature', undefined, {
      vehicle: vehicleTrackingData.value,
    });
    previouslySelectedColors.push(color.id);
  }
};

const open = () => emit('expand', 'paint');
const close = () => emit('expand', 'style');

const onCloseBtnClick = () => {
  track('click', 'close.button', 'feature', undefined, { vehicle: vehicleTrackingData.value });
  close();
};

const showExpanded = computed(() => {
  if (props.colors.length == 0 && isLoading.value) return false;
  return props.isExpanded;
});

const paintTitle = computed(() => (overPaint.value ?? props.selectedColor)?.name);

const isSelected = (color: Paint): boolean => color.id === props.selectedColor?.id;

const urlFromPaint = (color: Paint): string => `${color.imageUrl}?im=Resize,width=48`;

// ensure that when the radio group is focused, the selected color is focused. Makes it easier to navigate with keyboard
function focusInRadioGroup(e: FocusEvent) {
  if ((e.relatedTarget as Element)?.parentElement !== e.currentTarget && !(e.target as HTMLInputElement).checked)
    focusCheckedRadio(e.currentTarget as Element);
}

// focus the selected color when expanded
const focusCheckedRadio = (el: Element) => el.querySelector<HTMLInputElement>('input:checked')?.focus();

const buttonRef = ref<HTMLElement | null>(null);

watch([currentDirection, buttonRef], ([dir, btn]) => {
  if (btn instanceof HTMLElement) {
    const img = btn.querySelector('.wb-button__icon');
    if (img) {
      const btnRect = btn.getBoundingClientRect();
      const imgRect = img.getBoundingClientRect();
      const startPoint = dir == 'ltr' ? btnRect.right - imgRect.right : imgRect.left - btnRect.left;

      btn.parentElement?.style.setProperty('--start-point', `${startPoint}px`);
    }
  }
});

function expandedMouseDown() {
  mouseDown.value = true;
}

function expandedMouseUp(e: MouseEvent) {
  mouseDown.value = false;
  // re-focus the selected color when clicking inside the expanded color switcher but outside of any option
  if (!(e.target instanceof HTMLInputElement))
    (e.currentTarget as HTMLElement).querySelector<HTMLInputElement>('input:checked')?.focus();
}
</script>

<template>
  <div
    :class="[
      'owpi-mbusa-color-switcher',
      { 'owpi-mbusa-color-switcher--hidden': colors.length == 1, 'owpi-mbusa-color-switcher--loading': isLoading },
    ]"
  >
    <Transition @after-enter="focusCheckedRadio">
      <button
        v-if="!showExpanded"
        ref="buttonRef"
        :class="[
          'wb-button wb-button--secondary wb-button--medium wb-button--semi-transparent owpi-mbusa-color-switcher__button',
          { 'wb-button--theme-dark': isDark },
        ]"
        :title="$t('dynamicStage.colorSwitcher.expandBtn')"
        aria-label="Expand colors"
        type="button"
        :disabled="selectedColor ? undefined : 'true'"
        @click="open"
        @focus="open"
      >
        <img
          v-if="selectedColor"
          :src="urlFromPaint(selectedColor)"
          :alt="selectedColor.name"
          :title="selectedColor.name"
          class="wb-button__icon"
          aria-hidden="true"
        />
        <div v-else class="wb-button__icon" aria-hidden="true" />
        <wb-spinner v-if="isLoading" theme="light" />
        <span class="owpi-mbusa-color-switcher__button-text">
          {{ $t('dynamicStage.colorSwitcher.expandBtn') || '...' }}
        </span>
      </button>

      <div
        v-else
        class="owpi-mbusa-color-switcher__expanded"
        @mousedown="expandedMouseDown"
        @mouseup="expandedMouseUp"
        @keydown.esc="close"
      >
        <div v-if="paintTitle" class="owpi-mbusa-color-switcher__title wb-type-copy-tertiary">
          {{ paintTitle }}
        </div>
        <div
          class="owpi-mbusa-color-switcher__options"
          role="radiogroup"
          @focusin="focusInRadioGroup"
          @mouseleave="overPaint = undefined"
        >
          <input
            v-for="(color, i) in colors"
            :key="color.id"
            type="radio"
            class="owpi-mbusa-color-switcher__option"
            :style="`background-image: url(${urlFromPaint(color)}); --i: ${colors.length - i}`"
            :value="color.id"
            :checked="isSelected(color)"
            @change="switchColor(color, $event)"
            @mouseover="overPaint = color"
          />
        </div>
        <button
          type="button"
          aria-label="Close Button"
          :class="[
            'wb-round-button wb-round-button--small wb-round-button--solid-level-0 owpi-mbusa-color-switcher__close',
            { 'wb-round-button--theme-dark': isDark },
          ]"
          @click="onCloseBtnClick"
        >
          <wb-icon class="wb-round-button__icon" aria-hidden="true" name="close"></wb-icon>
        </button>
      </div>
    </Transition>
  </div>
</template>

<style lang="scss" scoped>
.owpi-mbusa-color-switcher {
  --highlight-color: var(--wb-blue-45);
  position: relative;
  display: flex;
  z-index: 2;
  height: 48px;

  & > * {
    height: 48px;
    position: absolute;
    inset-inline-end: 0;
  }

  &--hidden {
    visibility: hidden;
  }
  &__button {
    border-radius: 48px;
    padding-inline: 8px 16px;
    min-width: 0;
    border: none !important;
    white-space: nowrap;

    &-text {
      @extend %ellipsis;
      max-width: 100px;
    }

    > .wb-button__icon {
      width: 34px;
      height: 34px;
      border-radius: 100%;
      margin-inline-end: 8px;
      border: 2px solid var(--wb-white);
      pointer-events: none;
    }

    wb-spinner {
      position: absolute;
      inset-inline-start: 9px;
    }
  }

  &__expanded {
    display: flex;
    align-items: center;
    gap: var(--wb-spacing-xxs);
  }

  &__title {
    @extend %ellipsis;
    position: absolute;
    inset: auto auto calc(100% + var(--wb-spacing-xxs)) 50%;
    transform: translateX(-50%);
    max-width: min(var(--wb-grid-width), 376px);
    display: inline-block;
    background-color: var(--wb-grey-85);
    color: var(--wb-grey-40);
    border-radius: 12px;
    padding: 2px 12px;
    .owpi-mbusa-dynamic-stage--dark & {
      background-color: var(--wb-grey-35);
      color: var(--wb-grey-85);
    }

    @include breakpoint-to(mq3) {
      inset: calc(100% + var(--wb-spacing-xs) - 2px) auto auto 50%;
    }
  }

  &__options {
    display: flex;
    align-items: center;
    gap: var(--wb-spacing-3xs);
    border-radius: 44px;

    // on very small screens, the colors should be scrollable
    @include breakpoint-to(mq2) {
      overflow: auto hidden;
      max-width: calc(90vw - var(--wb-spacing-xxs) - 36px);
    }

    // add highlight around the paint group when focused with keyboard
    &:has(input:focus-visible) {
      box-shadow: 0 0 0 1px var(--highlight-color);
    }
  }

  &__option {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    appearance: none;
    margin: 4px;
    position: relative;
    transition:
      margin 0.1s ease-in-out,
      width 0.1s ease-in-out,
      height 0.1s ease-in-out;
    background-size: 48px;
    background-position: center;
    flex-shrink: 0;
    cursor: pointer;
    // default inline-start as 0, so the transition works
    inset-inline-end: 0;
    @media only screen and (max-width: 1380px) and (min-width: 1280px) {
      margin: 1.5px;
    }
    &:checked {
      width: 44px;
      height: 44px;
      margin: 0;
    }

    &:checked::after {
      content: '';
      position: absolute;
      inset: 4px;
      border-radius: 100%;
      border: 2px solid var(--wb-white);
    }

    &:focus {
      outline: none;
    }
  }

  &--loading &__option:checked::after {
    // spinning
    border-color: var(--highlight-color) var(--wb-grey-85) var(--wb-grey-85) var(--wb-grey-85);
    animation: pi-color-switcher-rotate 1s cubic-bezier(0.3, 0.1, 0.25, 0.65) 0s infinite normal none;
  }

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.3s $wb-fade;
    .owpi-mbusa-color-switcher__option {
      transition: inset 0.3s $wb-move !important;
    }
  }
  .v-enter-from,
  .v-leave-active {
    opacity: 0;
    .owpi-mbusa-color-switcher__option {
      inset-inline-end: calc((-48px * var(--i)) + var(--start-point));
    }
  }
}

@keyframes pi-color-switcher-rotate {
  to {
    transform: rotate(1turn);
  }
}
</style>
