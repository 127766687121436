import { Component, ConcreteComponent } from 'vue';

const camelCaseToDash = (str: string) => str.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();

export function propsFromElement(element: Element, vueComponent: Component) {
  return 'props' in vueComponent
    ? Object.fromEntries(
        Object.keys((vueComponent as ConcreteComponent).props).map((key) => [
          key,
          element.getAttribute(camelCaseToDash(key)),
        ]),
      )
    : {};
}
