import { computed, ref, type Ref } from 'vue';
import { StageVehicle } from '@dh-io-owpi/backend-api/src/data-contracts';
import { useTracking } from '@dh-io-owpi/shared/src/composables/useTracking';
import { useVehicleDeepLinksStore, VehicleDeepLinkState } from '@dh-io-owpi/shared/src/stores/vehicleDeepLinks';
import { AemData } from '@dh-io-owpi/shared/src/plugins/aemData';
import { buildCarConfiguratorUrl } from '@dh-io-owpi/shared/src/utils/buildCarConfiguratorUrl';

export type CtaType = 'shop' | 'configurator' | 'none';
export type CtaTypeMap = { primary?: CtaType; secondary?: CtaType };

export interface CtaOpt {
  type: 'configurator' | 'shop';
  url: string;
  icon: string;
  text: string;
}

export interface Cta extends CtaOpt {
  text: string;
  track: (e: PointerEvent | MouseEvent) => Promise<void>;
}

export interface StageCtas {
  primary?: Cta;
  secondary?: Cta;
}

const getCta = (
  type: CtaType | undefined,
  vehicle: StageVehicle | undefined,
  deeplinks: VehicleDeepLinkState,
): CtaOpt | undefined => {
  switch (type) {
    case 'configurator':
      return (
        vehicle &&
        deeplinks.CONFIGURATOR && {
          type,
          icon: deeplinks.CONFIGURATOR.icon,
          url: buildCarConfiguratorUrl(deeplinks.CONFIGURATOR.url, vehicle.vehicleId),
          text: deeplinks.CONFIGURATOR.label,
        }
      );
    case 'shop':
      return (
        deeplinks.ONLINE_SHOP && {
          type,
          url: deeplinks.ONLINE_SHOP.url,
          icon: deeplinks.ONLINE_SHOP.icon,
          text: deeplinks.ONLINE_SHOP.label,
        }
      );
  }
};

export function useStageCtas(
  aemData: AemData,
  ctas: CtaTypeMap,
  stageVehicle: Ref<StageVehicle | undefined>,
  enableHardCodedLinks?: boolean,
  deeplinks?: VehicleDeepLinkState,
) {
  const { createTrackLinkClickFn } = useTracking('dynamic_stage');
  let deeplinksRef: Ref<VehicleDeepLinkState | undefined> | undefined;

  const primaryCtaType = ctas.primary;
  const secondaryCtaType = ctas.secondary;

  function mapCta(kind: 'primary' | 'secondary', type: CtaType | undefined, deeplinks: VehicleDeepLinkState) {
    const cta = getCta(type, stageVehicle.value, deeplinks);

    return !cta
      ? undefined
      : ({
          ...cta,
          track: createTrackLinkClickFn(`cta.${kind}.${type}`),
        } as const);
  }

  if (primaryCtaType !== 'none' || secondaryCtaType !== 'none') {
    deeplinksRef = deeplinks ? ref(deeplinks) : useVehicleDeepLinksStore().get(aemData, enableHardCodedLinks);
  }

  return computed(() => {
    const links = deeplinksRef?.value;
    if (!links) return {};

    return {
      primary: mapCta('primary', primaryCtaType, links),
      secondary: mapCta('secondary', secondaryCtaType, links),
    };
  });
}
