<script lang="ts" setup>
import { ref, watch } from 'vue';
import type { Components } from '@workbench/core';
import { useTracking } from '@dh-io-owpi/shared/src/composables/useTracking';
import { useLoading } from '@dh-io-owpi/shared/src/composables/useLoading';
import { useResponsiveImage } from '@dh-io-owpi/shared-dynamic-stage/src/composables/useResponsiveImage';
import { mkBBDImgUrls } from '@dh-io-owpi/shared-dynamic-stage/src/utils/bbdImgUrls';
import { STAGE_INITIAL_PERSPECTIVE as initialPerspective } from '@dh-io-owpi/shared-dynamic-stage/src/utils/constants';
import { preloadImage } from '@dh-io-owpi/shared/src/utils/img.util';

import type { StageVehicle } from '../../lib/api';

type WB360 = HTMLElement & Components.Wb360Viewer;
type ImageConfig = Components.Wb360Viewer['images'][0];

const props = defineProps<{
  stageVehicle?: StageVehicle;
  darkMode?: boolean;
  hasErrors?: boolean;
}>();

const wbv = ref<WB360 | undefined>();

const { isLoading, load } = useLoading('stage');

const allImagesLoading = ref(true);

const ri = useResponsiveImage();

const currentPerspective = ref(initialPerspective / 10);

const rotationTracked = ref(false);
const { track } = useTracking();

const handleRotation = ({ detail }: CustomEvent<number>) => {
  currentPerspective.value = detail;

  if (rotationTracked.value || detail === initialPerspective / 10) return;
  track('click', 'stage_360', 'feature');
  rotationTracked.value = true;
};

function handleAllImagesLoaded() {
  allImagesLoading.value = false;
}

watch([wbv, ri, () => props.stageVehicle], ([wb360, responsiveWidth, stageVehicle]) => {
  if (stageVehicle && wb360) {
    allImagesLoading.value = true;
    const images = mkBBDImgUrls(stageVehicle.baseImageUrl, responsiveWidth);

    load(
      preloadImage(images[wb360.currentIndex]).then(() => {
        applyImagesSmoothly(
          wb360,
          images.map<ImageConfig>((src) => ({ highRes: { src } })),
        );
      }),
    );
  }
});

/**
 * Apply the images to the 360 viewer
 * But first set a transition to give a smooth image change
 * and reset it after, so it does not look bad when rotating
 */
function applyImagesSmoothly(wb360: WB360, images: ImageConfig[]) {
  const img = wb360.querySelector('.wb-360-viewer__image') as HTMLDivElement;

  if (img) {
    img.style.transition = 'cubic-bezier(0, 0, 0.3, 1) background 0.2s';
    setTimeout(() => (img.style.transition = ''), 200);
  }

  wb360.images = images;
}
</script>

<template>
  <div class="owpi-mbusa-threesixty-viewer" :disabled="isLoading || allImagesLoading">
    <wb-360-viewer
      ref="wbv"
      show-hint
      legacy-assets="false"
      :loading="(!stageVehicle || isLoading || allImagesLoading) && !hasErrors"
      :theme="darkMode ? 'dark' : 'light'"
      preload-high-res-images
      :current-index="initialPerspective / 10"
      @wbchange="handleRotation"
      @wbload="handleAllImagesLoaded"
    />
  </div>
</template>

<style lang="scss" scoped>
.owpi-mbusa-threesixty-viewer {
  position: relative;
  overflow: hidden;

  &[disabled='true'] {
    wb-360-viewer {
      pointer-events: none;
    }
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      max-width: var(--wb-grid-width);
      margin-inline: auto;
      cursor: not-allowed;
    }
  }
  :deep(wb-360-viewer) {
    // avoid jump while the JS for the custom component is loading by copy the default styles
    display: block;
    padding-top: 56.25%;
    z-index: 1;
    top: max(-1.5vw, -30px);

    max-width: var(--wb-grid-width);
    margin-inline: auto;

    &:focus {
      outline: none;
    }

    &:focus-visible::after {
      content: '';
      position: absolute;
      inset: 10% 0;
      border: 1px solid var(--wb-blue-45);
    }

    .wb-360-viewer__overlay {
      display: none;
    }

    @include breakpoint-to(mq2) {
      .wb-360-viewer__image {
        // we're scalling the stage content to fit better the content grid
        transform: scale(1.25);
      }
      .wb-360-hint {
        top: 55% !important;
      }
    }
    @include breakpoint-between(mq3, mq4) {
      margin-block: -10vw;
      .wb-360-hint {
        top: 50% !important;
      }
    }

    // desktop layout
    @include breakpoint-from(mq4) {
      margin-block: -10.5vw;
      max-width: calc(var(--wb-grid-width) - (var(--wb-spacing-m) * 2));
      .wb-360-viewer__image {
        // we're scalling the stage content to fit better the content grid
        transform: scale(0.85);
      }
    }
    @media (min-width: 1920px) {
      width: 1920px;
      height: 1080px;
      margin-block: -201px;
      padding-top: 0;
    }
  }
}
</style>
