import { FullRequestParams, HttpClient, HttpResponse } from './http-client';
import { panic } from '@dh-io-owpi/shared/src/utils/panic';

let http: HttpClient | undefined;
let baseUrl: string;

export async function request<T = any, E = any>(p: FullRequestParams): Promise<HttpResponse<T, E>> {
  if (!http) {
    http = baseUrl ? new HttpClient({ baseUrl, retries: p.retries }) : panic('API Client not initialized.');
  }

  return http.request(p);
}

export function initializeApi(apiBaseUrl: string) {
  if (baseUrl) return;
  baseUrl = apiBaseUrl;
}
