import { PartialAemEnvState, Environment, AemData, RenderMode } from './types';

/**
 * Map the AEM environment and component data to a single object.
 */
export function mapEnvToAemData<ComponentData>(
  aemEnv: PartialAemEnvState,
  componentData: ComponentData,
  dataEditMode?: string | null,
  renderMode: RenderMode = 'CSR',
): AemData<ComponentData> {
  const { country, language, vehicleData, stage } = aemEnv;

  return {
    isAemEditMode: dataEditMode == 'true',
    renderMode,
    country: mapAemAntarcticaCountry(country, language),
    language,
    modelSeries: vehicleData.modelSeries,
    subBrands: vehicleData.subBrands?.length ? vehicleData.subBrands : ['MERCEDES_BENZ'],
    stage: stage as Environment,
    ...componentData,
  };
}

// There is no Antarctica in the backend, so we need to replace it with valid ones
function mapAemAntarcticaCountry(country: string, language: string): string {
  if (country === 'AQ') return aqLanguageMap[language] ?? country;
  return country;
}

// map languages used on antarctica market to country codes
const aqLanguageMap: Record<string, string> = {
  de: 'DE',
  en: 'GB',
  es: 'ES',
  fr: 'FR',
  it: 'IT',
};
