import { createApp, type Component, type Plugin } from 'vue';
import { propsFromElement } from './props';
import { panic } from '../utils/panic';
import { getAemDataFromAEM, getAemAnalyticsData } from '../plugins/aemData/aem';
import { createAemDataPlugin } from '../plugins/aemData';

export function defineVueComponentAEM(vueComponent: Component, componentName: string, plugins?: Plugin[]) {
  const componentElements = document.querySelectorAll(`div[data-component-name="${componentName}"]`);

  componentElements.forEach((element) => {
    mountComponent({ element, componentName });
  });

  // Loading the event only in author
  if (((window.top ?? window) as any).aemNamespace.pageEnvironmentVariables.runMode === 'AUTHOR') {
    import('@seamless/vue3-aem-plugin/author')
      .then((data) => data.default({ componentName, mountComponent }))
      // eslint-disable-next-line no-console
      .catch((err) => console.error('Could not load author script', err));
  }

  type MountComponent = {
    element: Element;
    componentName: string;
  };

  /**
   * This method is the base to mount the Vue component in your application.
   * The `Element` is the main element where it will be mounted.
   * All the changes in this method will reflect both on the author vue3-aem-plugin logic and `index.ts`.
   * @param object - An object of type `MountComponent` with the parameters necessary to mount the component.
   */
  function mountComponent({ element }: MountComponent) {
    const props = propsFromElement(element, vueComponent);
    const componentId = element.getAttribute('component-id') ?? panic(`Component id not found on the window`);
    const dataEditMode = element.getAttribute('data-edit-mode');
    const aemAnalyticsData = getAemAnalyticsData((element as HTMLElement).dataset);

    getAemDataFromAEM<unknown>(componentId, dataEditMode).then((aemData) => {
      const app = createApp({ template: `${element.innerHTML} <${componentName} v-bind="$attrs" />` }, props)
        .component(componentName, vueComponent)
        .use(createAemDataPlugin({ ...aemAnalyticsData, ...aemData }));

      // Add plugins
      if (plugins) plugins.forEach((plugin) => app.use(plugin));

      app.mount(element);
    });
  }
}
