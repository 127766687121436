/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { request } from '.';
import { RequestParams } from './http-client';

import {
  Errors,
  GetPaintsParams,
  GetStylesParams,
  GetVehicleParams,
  StagePaints,
  StageStyles,
  StageVehicle,
} from './data-contracts';
// api

/**
 * @description Provides the vehicles name, price, image url, available perspectives and states for the requested model-series containing the given equipment-ids.
 *
 * @tags 2 - Stage
 * @name GetVehicle
 * @summary Endpoint for vehicle information.
 * @request GET:/api/v1/stage/country/{country}/language/{language}/model-series/{model-series}
 */
export const getVehicle = (
  { country, language, modelSeries, ...query }: GetVehicleParams,
  params: RequestParams = {},
) =>
  request<StageVehicle, Errors>({
    path: `/api/v1/stage/country/${country}/language/${language}/model-series/${modelSeries}`,
    method: 'GET',
    query: query,
    format: 'json',
    ...params,
  });
/**
 * @description Provides a list of all available paints for the requested model-series, containing the equipment code, a description and the image URL for each paint.
 *
 * @tags 2 - Stage
 * @name GetPaints
 * @summary Endpoint for information about paints for the colour switcher within the stage component.
 * @request GET:/api/v1/stage/country/{country}/language/{language}/model-series/{model-series}/paints
 */
export const getPaints = ({ country, language, modelSeries, ...query }: GetPaintsParams, params: RequestParams = {}) =>
  request<StagePaints, Errors>({
    path: `/api/v1/stage/country/${country}/language/${language}/model-series/${modelSeries}/paints`,
    method: 'GET',
    query: query,
    format: 'json',
    ...params,
  });
/**
 * @description Provides a list of all available styles for the requested model-series, containing the equipment code, and a name for each style.
 *
 * @tags 2 - Stage
 * @name GetStyles
 * @summary Endpoint for information about styles for the style switcher within the stage component.
 * @request GET:/api/v1/stage/country/{country}/language/{language}/model-series/{model-series}/styles
 */
export const getStyles = ({ country, language, modelSeries, ...query }: GetStylesParams, params: RequestParams = {}) =>
  request<StageStyles, Errors>({
    path: `/api/v1/stage/country/${country}/language/${language}/model-series/${modelSeries}/styles`,
    method: 'GET',
    query: query,
    format: 'json',
    ...params,
  });
