import type { Deeplinks } from './damJSONdataTypes';
export { getHardCodedLinks } from './hardCodedLinks';
import { deeplinkAssetURL } from './constants';
// import { browser, dev } from '@dh-io-owpi/shared/src/environment';

/**
 * @description Provides the configurator, online shop and test drive urls with the correspondent labels.
 *
 * @name getDeeplinks
 * @summary Endpoint for get deeplinks by Model Series
 * @request GET:/v1/deeplinks/${country}/${language}/model-series/${modelSeries}
 *
 */
export const getDeeplinks = () => {
  // To avoid local cors problems with deeplink service we get the data from a local proxy server
  // const baseUrl =
  //   !dev || !browser || 'IS_ON_PW' in window
  //     ? apiVehicleDeeplinksBaseUrl
  //     : new URL(import.meta.url).origin + '/vehicle-deeplinks-api';

  return fetch(deeplinkAssetURL).then((r) => r.json()) as Promise<Deeplinks>;
};
