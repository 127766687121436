/**
 * @description Method used to remove the last n segments from a string
 * @param {string} str
 * @param {number} num number of segments to be removed
 * @param {string} sep defaults to '/' char
 * @returns string with the last n segments removed
 */
export function removeLastNSegments(str: string, num: number, sep = '/'): string {
  for (let index = 0; index < num; index++) {
    str = str.substring(0, str.lastIndexOf(sep));
  }

  return str;
}
