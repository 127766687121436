<script lang="ts" setup>
import { computed, defineAsyncComponent, provide, ref } from 'vue';

import { vAnchor } from '@dh-io-owpi/shared/src/directives/anchor.directive';
import { KEY_TRACKING_ACTION, KEY_VEHICLE_TRACKING_DATA } from '@dh-io-owpi/shared/src/composables/useTracking';
import type { ValidationNotification } from '@dh-io-owpi/shared/src/components/ErrorList/ValidationNotification';
import { CtaTypeMap } from '@dh-io-owpi/shared-dynamic-stage/src/composables/useStageCtas';
import { AemData } from '@dh-io-owpi/shared/src/plugins/aemData';
import { Paint, StageVehicle, Style } from './lib/api';
import { SwitcherKind } from './lib/types/switchers';
import { useLoading } from '@dh-io-owpi/shared/src/composables/useLoading';
import { usePurchasePriceTooltip } from '@dh-io-owpi/shared-dynamic-stage/src/composables/usePurchasePriceTooltip';
import { isCampaignModel } from '@dh-io-owpi/shared-dynamic-stage/src/utils/campaigns';

// components
import StageBg from './components/StageBg/StageBg.vue';
import ThreesixtyViewer from './components/ThreesixtyViewer/ThreesixtyViewer.vue';
import SalesBar from './components/SalesBar/SalesBar.vue';
import ColorSwitcher from './components/ColorSwitcher/ColorSwitcher.vue';
import StyleSwitcher from './components/StyleSwitcher/StyleSwitcher.vue';
import { STAGE_ERROR_MESSAGE } from '@dh-io-owpi/shared-dynamic-stage/src/utils/constants';
const ErrorList = defineAsyncComponent(() => import('@dh-io-owpi/shared/src/components/ErrorList/ErrorList.vue'));

provide(KEY_TRACKING_ACTION, 'dynamic_stage');

const props = defineProps<{
  aemData: AemData;
  isDark: boolean;
  ctas: CtaTypeMap;
  errors: (ValidationNotification | string)[];
  purchasePriceTooltip?: string;
  stageVehicle?: StageVehicle;
  stageStyles?: Style[];
  selectedStyle?: Style;
  stagePaints?: Paint[];
  selectedPaint?: Paint;
}>();

const emit = defineEmits<{
  (event: 'switchPaint', color: Paint): void;
  (event: 'switchStyle', style: Style): void;
}>();

const vehicleTrackingData = computed(
  () =>
    props.stageVehicle && {
      modelCode: props.aemData.modelSeries,
      modelName: props.stageVehicle.modelName,
      vehicleId: props.stageVehicle.vehicleId,
      subBrand: props.selectedStyle?.brandId,
    },
);

provide(KEY_VEHICLE_TRACKING_DATA, vehicleTrackingData);

const switchStyle = (style: Style) => emit('switchStyle', style);
const switchPaint = (paint: Paint) => emit('switchPaint', paint);

const { isLoading } = useLoading('stage');

const hasErrors = computed(() => props.errors.length > 0);

const expandedSwitcher = ref<SwitcherKind>('style');
const expandSwitcher = (kind: SwitcherKind) => (expandedSwitcher.value = kind);
const purchasePriceTooltip = usePurchasePriceTooltip(props.purchasePriceTooltip);

const isCampaignCandidate = computed(() => isCampaignModel(props.aemData.modelSeries, props.aemData.subBrands[0]));
</script>

<template>
  <div v-anchor="'stage'" :class="['owpi-mbusa-dynamic-stage', { 'owpi-mbusa-dynamic-stage--dark': isDark }]">
    <slot />
    <StageBg
      v-if="stageVehicle || !isCampaignCandidate"
      :isDark="isDark"
      :campaignBg="stageVehicle?.backgroundImageUrl"
      class="owpi-mbusa-dynamic-stage__bg"
    />
    <ErrorList
      v-if="aemData.isAemEditMode && hasErrors"
      class="owpi-mbusa-dynamic-stage__error-list"
      :errors="errors"
    />
    <div
      v-if="!aemData.isAemEditMode && !stageVehicle && !isLoading && hasErrors"
      class="owpi-mbusa-dynamic-stage__error wb-type-copy"
    >
      {{ $t('dynamicStage.errorMessage') ?? STAGE_ERROR_MESSAGE }}
    </div>

    <ThreesixtyViewer
      class="owpi-mbusa-dynamic-stage__360-viewer"
      :stageVehicle="stageVehicle"
      :darkMode="isDark"
      :hasErrors="hasErrors"
    />

    <h1 class="owpi-mbusa-dynamic-stage__heading wb-type-heading-xl">
      {{ stageVehicle?.modelName ?? '' }}
    </h1>

    <StyleSwitcher
      v-if="stageStyles && stageStyles.length > 1"
      class="owpi-mbusa-dynamic-stage__switchers owpi-mbusa-dynamic-stage__switchers--style"
      :isDark="isDark"
      :styles="stageStyles.slice(0, 5)"
      :selectedStyle="selectedStyle"
      :isExpanded="expandedSwitcher === 'style'"
      @switch="switchStyle"
      @expand="expandSwitcher"
    />
    <div v-else class="owpi-mbusa-dynamic-stage__switchers owpi-mbusa-dynamic-stage__switchers--style" />
    <ColorSwitcher
      class="owpi-mbusa-dynamic-stage__switchers owpi-mbusa-dynamic-stage__switchers--color"
      :colors="stagePaints ?? []"
      :selectedColor="selectedPaint"
      :isExpanded="expandedSwitcher === 'paint'"
      :isDark="isDark"
      @switch="switchPaint"
      @expand="expandSwitcher"
    />

    <SalesBar
      class="owpi-mbusa-dynamic-stage__sales-bar"
      :stageVehicle="stageVehicle"
      :aemData="aemData"
      :ctas="ctas"
      :purchasePriceTooltip="purchasePriceTooltip"
      :isDark="isDark"
      :selectedStyle="selectedStyle"
      :selectedColor="selectedPaint"
    />
  </div>
</template>

<style lang="scss">
@import './variables.sass';

div[data-component-name='owpi-mbusa-dynamic-stage'],
.owpi-mbusa-dynamic-stage {
  // avoid FOUC by setting the min-height to the stage
  @include breakpoint-to(mq2) {
    min-height: calc(
      #{$sales-bar-height} + #{$mobile-color-switcher-outer-height} + #{$car360-ratio-height} + #{$mobile-heading-outer-height} +
        #{$mobile-style-switcher-outer-height}
    );
  }
  @include breakpoint-between(mq3, mq4) {
    min-height: calc(
      #{$sales-bar-height} + #{$mobile-color-switcher-outer-height} + #{$car360-ratio-height} - (10vw * 2) + #{$mobile-heading-outer-height} +
        #{$mobile-style-switcher-outer-height}
    );
  }
  @include breakpoint-from(mq4) {
    min-height: calc(
      #{$car360-ratio-height} - (10.5vw * 2) + #{$desktop-switchers-outer-height} + #{$desktop-sales-bar-outer-height}
    );
  }
  @media (min-width: 1920px) {
    min-height: 866px;
  }
}

.owpi-mbusa-dynamic-stage {
  --stage-bg-color: var(--wb-white);
  --stage-fg-color: var(--wb-black);

  &--dark {
    --stage-bg-color: var(--wb-grey-15);
    --stage-fg-color: var(--wb-white);
  }

  background-color: var(--stage-bg-color);

  position: relative;
  display: grid;
  grid-auto-flow: column;
  > * {
    grid-column: 1;
  }

  &__error,
  &__error-list {
    z-index: 2;
    justify-self: center;
    color: var(--stage-fg-color);
    max-width: var(--wb-grid-width);
  }
  &__error {
    align-self: center;
  }

  &__switchers {
    &--style {
      position: relative;
    }

    &--color {
      justify-self: end;
      margin-inline-end: calc((100vw - var(--wb-grid-width)) / 2);
    }
  }

  // small version layout (mobile)
  @include breakpoint-to(mq3) {
    &__bg {
      grid-row: 2 / 6;
    }
    &__sales-bar {
      grid-row: 1;
    }
    &__switchers--color {
      grid-row: 2;
      margin-block: $mobile-color-switcher-mt $mobile-color-switcher-mb;
    }
    &__360-viewer {
      grid-row: 3;
    }
    &__error,
    &__error-list {
      grid-row: 3 / 4;
    }
    &__heading {
      @include wb-content-width;
      display: grid;
      align-items: center;
      position: relative;
      text-align: center;
      grid-row: 4;
      margin-block: 0 $mobile-heading-mb;
      min-height: $mobile-heading-height;
      color: var(--stage-fg-color);
    }
    &__switchers--style {
      @include wb-content-width;
      grid-row: 5;
      margin-block: 0 $mobile-style-switcher-mb;
      height: 70px;
    }
  }

  // big version layout (tablet/desktop)
  @include breakpoint-from(mq4) {
    &__bg {
      grid-row: 1 / 4;
    }

    &__360-viewer {
      padding-top: 20px;
      grid-row: 1;
    }

    &__error,
    &__error-list {
      grid-row: 1 / 2;
    }

    &__switchers {
      grid-row: 2;
      margin-block: 0 $desktop-switchers-mb;
    }

    &__sales-bar {
      grid-row: 3;
    }

    &__heading {
      display: none;
    }
  }
}
</style>
