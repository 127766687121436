import { ref, provide, inject, Ref } from 'vue';

export type LoadingCtx = {
  isLoading: Ref<boolean>;
  load<T>(p: Promise<T>): Promise<T>;
};

/**
 *
 * Creates a loading service that can be used to track multiple loading operations.
 * When all operations are done, the loading state is set to false.
 */
export function useLoading(key = 'global') {
  const loadingKey = 'useLoading_' + key;

  let ctx: LoadingCtx | null = inject(loadingKey, null);
  if (ctx) return ctx;

  const isLoading = ref(false);
  let loadingCount = 0;

  function start() {
    loadingCount++;
    isLoading.value = true;
  }
  function stop() {
    loadingCount--;
    if (loadingCount <= 0) isLoading.value = false;
  }

  ctx = {
    isLoading,
    /**
     * Tracks a loading operation.
     */
    load<T>(p: Promise<T>): Promise<T> {
      start();
      return p.finally(stop);
    },
  };

  // make the loading service available to all children
  provide(loadingKey, ctx);

  return ctx;
}
