import { ref, Ref } from 'vue';

export type Direction = 'ltr' | 'rtl';

const _dirRef = ref(document.documentElement.dir as Direction);

export const currentDirection = _dirRef as Readonly<Ref<Direction>>;

// triggered by workbench DirectionService: https://pages.git.i.mercedes-benz.com/dh-io-workbench/documentation/v6/utility-functions/DirectionService/
document.addEventListener(
  'wbdirchange',
  (event) => (_dirRef.value = (event as CustomEvent<{ dir: Direction }>).detail.dir),
);
