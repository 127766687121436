<script lang="ts" setup>
import { inject, ref, watch } from 'vue';
import { useLoading } from '@dh-io-owpi/shared/src/composables/useLoading';
import type { Style } from '../../lib/api';
import { SwitcherKind } from '../../lib/types/switchers';
import { useTracking, KEY_VEHICLE_TRACKING_DATA } from '@dh-io-owpi/shared/src/composables/useTracking';

const props = defineProps<{
  styles: Style[];
  selectedStyle?: Style;
  switchStyle(style: Style): void;
  isSelected(style: Style): boolean;
  isDark: boolean;
  isExpanded: boolean;
}>();

const emit = defineEmits<{
  (event: 'expand', kind: SwitcherKind): void;
}>();

const vehicleTrackingData = inject(KEY_VEHICLE_TRACKING_DATA)!;
const { track } = useTracking('dynamic_stage|style_switcher');

const open = () => {
  emit('expand', 'style');
  track('click', 'all_styles', 'feature', undefined, { vehicle: vehicleTrackingData.value });
};

const { isLoading } = useLoading('stage');

/// button group for large version
const elRef = ref<HTMLElement>();

watch([elRef, () => props.selectedStyle, () => props.isExpanded], ([el, style]) => {
  /**
   * wb-button-group logic to set the selected item, does not work when we mount with Vue
   * so we need to do it manually, by triggering a change event on it
   * https://git.i.mercedes-benz.com/dh-io-workbench/core/blob/6.9.7/src/components/button-group/button-group.tsx#L23
   */
  if (el && style) setTimeout(() => el.dispatchEvent(new Event('change')), 100);
});
</script>
<template>
  <wb-button-group ref="elRef" class="owpi-mbusa-style-switcher-buttons" v-bind="$attrs">
    <template v-if="isExpanded">
      <label
        v-for="style in styles"
        :key="style.id"
        :title="style.name"
        :class="[
          'wb-button wb-button--secondary wb-button--medium wb-button--semi-transparent',
          { 'wb-button--theme-dark': isDark },
        ]"
        :disabled="isLoading ? 'true' : undefined"
      >
        <input type="radio" name="group" :checked="isSelected(style)" @change="switchStyle(style)" />
        <span class="owpi-mbusa-style-switcher-buttons__label-text">{{ style.name }}</span>
      </label>
    </template>
    <button
      v-else
      type="button"
      :class="[
        'wb-button wb-button--secondary wb-button--medium wb-button--semi-transparent',
        { 'wb-button--theme-dark': isDark },
      ]"
      aria-label="Expand styles"
      @click="open"
    >
      {{ $t('dynamicStage.styleSwitcher.expandBtn') }}
    </button>
  </wb-button-group>
</template>
<style lang="scss">
.owpi-mbusa-style-switcher-buttons {
  max-width: 100%;

  &__label-text {
    @extend %ellipsis;
  }
}
</style>
