import { browser, version } from '@dh-io-owpi/shared/src/environment';

/**
 * Dispatch a tracking event using owc connection aem tracking
 */
export async function dispatchTrackingEvent(
  eventType: string,
  label: string,
  category: 'feature' | 'link' | 'impression',
  action: string,
  url?: string,
  additionalData?: any,
): Promise<void> {
  if (browser)
    await import('./tracking.browser').then(({ track }) =>
      track(version, eventType, label, category, action, url, additionalData),
    );
}
