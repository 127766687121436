import type { Deeplinks, GetDeeplinksParams } from './damJSONdataTypes';

/**
 * Map of hardcoded links grouped by country
 */
const hardCodedLinks = new Map<string, Deeplinks>([
  [
    'ZA',
    {
      ONLINE_SHOP: {
        label: 'Show available vehicles',
        url: 'https://shop.mercedes-benz.com/en-za/shop/vehicle/srp/new?sort=relevance&assortment=vehicle&model=A-CLASS&bodyType=HATCHBACK',
      },
    },
  ],
  [
    'IE',
    {
      ONLINE_SHOP: {
        label: 'Show available vehicles',
        url: 'https://shop.mercedes-benz.ie/checkoutnew/?model%5B0%5D=A-Class&nfcSearchVersion=1.0.0',
      },
    },
  ],
]);

const modelSeriesAllowed = ['W177-fl'];

export const getHardCodedLinks = ({ country, modelSeries }: GetDeeplinksParams) =>
  modelSeriesAllowed.includes(modelSeries) && hardCodedLinks.get(country);
