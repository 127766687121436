import { computed, ref, Ref } from 'vue';
import type { MediaQueryChangeEvent, MediaQuery } from '@workbench/core';
import { browser } from '@dh-io-owpi/shared/src/environment';

/**
 * This is copy of the breakpoints from @workbench/core
 * We can't simply import it because it will bring the whole @workbench/core package
 */
export const wbBreakpoints: Record<MediaQuery, number> = {
  mq1: 320,
  mq2: 480,
  mq3: 768,
  mq4: 1024,
  mq5: 1280,
  mq6: 1440,
} as const;

const _mqRef = ref(getInitialMQ());

/**
 * This is a ref that contains the current mq, and will be updated when the window is resized
 */
export const currentMediaQuery = _mqRef as Readonly<Ref<MediaQuery>>;

export const useMatchMediaQuery = (...mqList: MediaQuery[]) => computed(() => mqList.includes(_mqRef.value));

/**
 * Get the initial size of the upper breakpoint based on window
 */
function getInitialMQ(): MediaQuery {
  if (!browser) return 'mq6';
  const width = window.innerWidth;
  for (const [mq, size] of Object.entries(wbBreakpoints).slice(1).reverse()) {
    if (width >= size) return mq as MediaQuery;
  }
  return 'mq1';
}

if (browser)
  window.addEventListener(
    'wbresize',
    (evt) => (_mqRef.value = (evt as CustomEvent<MediaQueryChangeEvent>).detail.current),
  );
