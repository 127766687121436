/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Creates a function that will be called on the next idle period.
 * If other calls to the function are made before the idle period, the function will be called only once.
 */
export function debounceIdle<Args extends Array<unknown>>(fn: (...args: Args) => void) {
  let ric: ReturnType<typeof requestIdleCallback>;
  return (...args: Args) => {
    cancelIdleCallback(ric);
    ric = requestIdleCallback(() => fn(...args));
  };
}

/**
 * @name debounce
 *
 * @description Debounce a function by a given time in milliseconds
 * Method copied from '@workbench/core'
 *
 * @example
 *
 * const debouncedFn = debounce(fn, 300);
 */
export const debounce = <Args extends Array<any>>(
  fn: (...args: Args) => void,
  milliseconds: number,
): ((...args: Args) => void) => {
  let scheduled: ReturnType<typeof setTimeout> | undefined;

  return (...args) => {
    clearTimeout(scheduled);
    scheduled = setTimeout(fn, milliseconds, ...args);
  };
};

/**
 * @name throttle
 *
 * @description Throttles a function by a given time in milliseconds
 * Method copied from '@workbench/core' and adapted.
 *
 * @example
 *
 * const throttledFn = throttle(fn, 300);
 */
export const throttle = (
  fn: (...args: Array<any>) => unknown,
  milliseconds: number,
  immediate = false,
): (() => void) => {
  let timeout: number | undefined;
  let initialCall = true;

  return function (...args: Array<unknown>) {
    const callNow = immediate && initialCall;
    const next = () => {
      fn(...args);
      timeout = undefined;
    };

    if (callNow) {
      initialCall = false;
      next();
    } else if (!timeout) {
      timeout = window.setTimeout(next, milliseconds);
    }
  };
};
