// import { getTranslation } from '@owc/connection-aem-translations';
import { type AemData } from '../aemData';
import { useI18n } from './index';
import { I18nCtx } from './types';
import i18AssetURL from './constants';
import fetch from 'cross-fetch';

/**
 * Loads the i18n messages from Phrase using AEM environment and sets them in the i18n plugin.
 * Requires i18n plugin to be installed.
 */
export async function provideI18nFromAEM(aemData: AemData) {
  const i18n = useI18n();

  await loadI18nFromAEM(i18n, aemData);
}

// gets i18 data
const getTranslations = async (): Promise<any> => {
  try {
    const hostname = window.location.hostname;
    const response = await fetch('//' + hostname + i18AssetURL);
    if (!response.ok) {
      throw new Error(`Failed to load ${'https://' + hostname + i18AssetURL} JSON file`);
    }
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error(error);
  }
};

export async function loadI18nFromAEM(i18n: I18nCtx, aemData: AemData) {
  i18n.setDebug(aemData.isAemEditMode);
  i18n.setMessages(await getTranslations());
}
