import { ref, Ref, watchEffect } from 'vue';

export function usePinnedSalesBar(): {
  elRef: Ref<HTMLDivElement | undefined>;
  isPinned: Ref<boolean>;
} {
  const isPinned = ref(false);

  const observer = new IntersectionObserver(([e]) => (isPinned.value = e.boundingClientRect.top < 0), { threshold: 1 });

  const elRef = ref<HTMLDivElement>();

  watchEffect((onCleanup) => {
    const el = elRef.value;
    if (!el) return;

    observer.observe(el);
    onCleanup(() => observer.unobserve(el));
  });

  return { elRef, isPinned };
}
