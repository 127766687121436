import { wbBreakpoints } from '@dh-io-owpi/shared/src/composables/useMediaQuery';
import type { MediaQuery } from '@workbench/core';

type CroppingParams = {
  y: number;
  width: number;
  // actual width of the background on the page.
  // we let BBD resize to this width to avoid overfetching.
  displayWidth?: number;
  height: number;
};

const CAMPAIGN_BG_WIDTH = 3000;

// displayWidth is minimum width for next MQ to ensure a sharp image when resizing
export const campaignBgCropsMvp: Record<MediaQuery, CroppingParams> = {
  mq1: {
    y: 1347,
    width: 1615,
    displayWidth: 480,
    height: 2215,
  },
  mq2: {
    y: 1294,
    width: 1772,
    displayWidth: 768,
    height: 1591,
  },
  mq3: {
    y: 1177,
    width: 2304,
    displayWidth: 1024,
    height: 1130,
  },
  mq4: {
    y: 1177,
    width: 2330,
    displayWidth: 1280,
    height: 1149,
  },
  mq5: {
    y: 1198,
    width: 2312,
    displayWidth: 1440,
    height: 1095,
  },
  mq6: {
    y: 1260,
    width: CAMPAIGN_BG_WIDTH,
    height: 981,
  },
} as const;

export function getCroppedCampaignBgUrl(bgUrl: string, croppingParams: CroppingParams) {
  const { y, width, height, displayWidth } = croppingParams;
  // calculate 'x' to center the crop
  const x = Math.round(CAMPAIGN_BG_WIDTH / 2 - width / 2);
  const url = new URL(bgUrl);
  url.searchParams.set(
    'im',
    `Crop,rect=(${x},${y},${width},${height})${displayWidth ? `;Resize,width=${displayWidth}` : ''}`,
  );
  return url.href;
}

export function generateCampaignSrcSet(
  bgUrl: string,
  croppingInfo: Record<MediaQuery, CroppingParams>,
): { srcset: string; media: string }[] {
  return (
    Object.entries(croppingInfo)
      .map(([mq, params]) => ({
        srcset: getCroppedCampaignBgUrl(bgUrl, params),
        media: `(min-width: ${wbBreakpoints[mq as MediaQuery]}px)`,
      }))
      // we need to reverse the MQ list, since the browser will go from
      // top to bottom and will use the img src for the first matching query.
      // Since e.g. 1920px also satisfies (min-width: 320px) it will always choose the MQ1 url.
      .reverse()
  );
}

/**
 * Creates an array of BBD image URLs for a given base URL and responsive width.
 */
export function mkBBDImgUrls(baseUrl: string, responsiveWidth: number): string[] {
  return Array.from({ length: 36 }, (_, i) => mkBBDImgUrl(baseUrl, i * 10, responsiveWidth));
}

export function mkBBDImgUrl(baseUrl: string, perspective: number, responsiveWidth = 1920): string {
  // ensure we fetch webp images from BBD CE
  const webpBaseUrl = baseUrl.replace('IMGT=P27', 'IMGT=W27').replace('iris.png', 'iris.webp');

  // if the responsive width is 1920, we don't need to resize the images
  // using Akamai's image resizing service, to roll back to CE images, use `&imwidth=${responsiveWidth}` instead
  const akamaiIM = responsiveWidth == 1920 ? '' : `&im=Resize,width=${responsiveWidth}`;
  return `${webpBaseUrl}&POV=BE${`${perspective}`.padStart(3, '0')}&uni=m${akamaiIM}`;
}
