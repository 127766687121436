import { initializeStore } from '@seamless/store';
import { type AEMComponentPrefill } from '@owc/component-registry-connection';
import { type ConnectionAemEnvironmentState, connectionName } from '@owc/connection-aem-environment';

import { AemAnalyticsData, AemData, PartialAemEnvState } from './types';
import { mapEnvToAemData } from './utils';

export async function getAemDataFromAEM<ComponentData>(
  componentId: string,
  dataEditMode: string | null,
): Promise<AemData<ComponentData>> {
  const [env, data] = await Promise.all([
    getAemEnvironmentVariables(),
    getAemComponentData<ComponentData>(componentId),
  ]);
  return mapEnvToAemData(env as PartialAemEnvState, data, dataEditMode);
}

async function getAemComponentData<ComponentData>(componentId: string): Promise<ComponentData> {
  // add the connection if not available
  const componentRegistry = (window.top ?? window).owc?.componentRegistry ?? (await initializeComponentRegistry());

  return new Promise<ComponentData>((resolve) => {
    const handleDataChanged: ComponentListener = ((data: AEMComponentPrefill) => {
      resolve(data.payload as ComponentData);
      componentRegistry.unsubscribe(componentId, handleDataChanged);
    }) as ComponentListener;

    componentRegistry.subscribe(componentId, handleDataChanged);
  });
}

export function getAemAnalyticsData(dataset: DOMStringMap): AemAnalyticsData {
  return {
    aemModulename: dataset.aemModulename,
    aemModuletrackingname: dataset.aemModuletrackingname,
    aemTargetingHiddenInitially: dataset.aemTargetingHiddenInitially as 'checked' | undefined,
    aemTargetingUseCase: dataset.aemTargetingUseCase,
    aemTargetingUseCaseVariant: dataset.aemTargetingUseCaseVariant,
    aemTargetingVehicle: dataset.aemTargetingVehicle,
  };
}

// this is sadly not exported from the @owc/component-registry-connection
type ComponentListener = (data: Record<string, unknown>) => unknown;

// Get current AEM environment variables. Note, that the `aemNamespace` property should be provided
// on the window object.
export async function getAemEnvironmentVariables(): Promise<ConnectionAemEnvironmentState> {
  // only add the connection in test mode, because it's not needed in production
  if (import.meta.env.MODE === 'test') await initializeAemEnvironmentForTest();

  return new Promise((resolve) => initializeStore().once(connectionName, resolve));
}

/**
 * When running tests we need to add the connection to the store.
 * Try to only add it once, to avoid warnings in the console.
 */
async function initializeAemEnvironmentForTest() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (!initializeStore().connections.has(connectionName))
    await (await import('@owc/connection-aem-environment')).addConnectionAemEnvironmentConnection();
}

/**
 * Manually initialize the component registry.
 */
async function initializeComponentRegistry() {
  const cr = await import('./owc-lazy').then((m) => m.initializeComponentRegistry());

  // in test mode we need to wait a bit
  if (import.meta.env.MODE === 'test') return await new Promise((resolve) => setTimeout(() => resolve(cr)));

  return cr;
}
