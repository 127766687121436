<script lang="ts" setup>
import { computed, onErrorCaptured, watch } from 'vue';
import { useJsonLd } from '@dh-io-owpi/shared-dynamic-stage/src/composables/useJsonLd';
import { CtaTypeMap } from '@dh-io-owpi/shared-dynamic-stage/src/composables/useStageCtas';

import { useStage } from './composables/useStage';
import DynamicStage from './DynamicStage.vue';
import { useAemData } from '@dh-io-owpi/shared/src/plugins/aemData';
import { provideI18nFromAEM } from '@dh-io-owpi/shared/src/plugins/i18n/aem';
import { sendCodeError } from '@dh-io-owpi/shared/src/services/Error';
import { ComponentData } from './lib/types/aem';
import { initializeApi } from '@dh-io-owpi/backend-api';
import { getConfiguration } from '@dh-io-owpi/shared/src/lib/configuration';

onErrorCaptured(sendCodeError);

const aemData = useAemData<ComponentData>();

provideI18nFromAEM(aemData);

initializeApi(getConfiguration(aemData.stage).apiBaseUrl);

const {
  stageVehicle,
  stagePaints,
  stageStyles,
  errorMessage,
  validationErrors,
  selectedColor,
  selectedStyle,
  switchColor,
  switchStyle,
} = useStage(aemData);

const errors = computed(() =>
  validationErrors.value?.length ? validationErrors.value : errorMessage.value ? [errorMessage.value] : [],
);

const ctas: CtaTypeMap = {
  primary: aemData.primaryCtaType ?? 'shop',
  secondary: aemData.secondaryCtaType ?? 'configurator',
};

const isDark = aemData.subBrands?.includes('MERCEDES_AMG') ?? false;

const unwatch = watch(
  stageStyles,
  (newStageStyles) => {
    const userStyleId = newStageStyles?.[0]?.id;
    if (userStyleId) {
      useJsonLd(aemData, userStyleId);
      unwatch();
    }
  },
  { deep: true },
);

aemData.purchasePriceTooltip =
  'Starting price is MSRP, or Manufacturer’s Suggested Retail Price. MSRP excludes transportation and handling charges, destination charges, taxes, title, registration, license, tag, preparation and documentary service fees, insurance charges, and Dealer add-on products, accessories and associated labor and installation charges. MSRP for a base model prior to customer build also excludes charges for optional equipment, products, packages, and accessories. Actual vehicle price and availability may vary by Dealer and should be confirmed with the dealer selected by customer.';
</script>

<template>
  <dynamic-stage
    :aemData="aemData"
    :isDark="isDark"
    :ctas="ctas"
    :errors="errors"
    :purchasePriceTooltip="aemData.purchasePriceTooltip"
    :stageVehicle="stageVehicle"
    :stageStyles="stageStyles"
    :selectedStyle="selectedStyle"
    :stagePaints="stagePaints"
    :selectedPaint="selectedColor"
    @switchStyle="switchStyle"
    @switchPaint="switchColor"
  >
    <slot />
  </dynamic-stage>
</template>
