/**
 * Whitelist of [modelSeries, subBrand] combinations which may have a campaign(bg)
 */
export const CAMPAIGN_MODELS = [
  ['W177-fl', 'MERCEDES_BENZ'],
  ['V177-fl', 'MERCEDES_BENZ'],
];

export const isCampaignModel = (modelSeries: string, subBrand: string) =>
  CAMPAIGN_MODELS.some(
    ([campaignModelSeries, campaignSubBrand]) => modelSeries === campaignModelSeries && subBrand === campaignSubBrand,
  );
