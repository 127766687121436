import { FunctionDirective } from 'vue';

/**
 * This transition allows to enable transitions only when we change a parameter,
 * disabling them after finish transitioning.
 * It's useful when transitions might not be desirable (like resizing the page)
 *
 * ```html
 *  <div v-transition="someVarThatChanges" :class="['comp',{on: someVarThatChanges}]">
 * ```
 * ```css
 *  .comp {
 *    width: 200px;
 *  }
 *  .comp.on {
 *    width: 100%;
 *  }
 *  .comp[data-transition] {
 *    transition: width 0.5s;
 *  }
 * ```
 */
export const vTransition: FunctionDirective = (el, binding) => {
  if (binding.oldValue !== undefined && binding.oldValue !== binding.value) {
    el.dataset.transition = 'true';
    el.addEventListener('transitionend', () => delete el.dataset.transition, { once: true });
  }
};
