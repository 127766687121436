const damVehicleDataAssetURL = '/content/dam/mb-nafta/us/dynamic-stage/dynamic-stage-model.json';

export default damVehicleDataAssetURL;

export const abstractColors = [
  { abstract: 'BLK', name: 'black' },
  { abstract: 'BLU', name: 'blue' },
  { abstract: 'BWN', name: 'brown' },
  { abstract: 'GRN', name: 'green' },
  { abstract: 'GRY', name: 'grey' },
  { abstract: 'RED', name: 'red' },
  { abstract: 'SLV', name: 'silver' },
  { abstract: 'WHT', name: 'white' },
  { abstract: 'YLW', name: 'yellow' },
];
