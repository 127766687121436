import type VehicleInformation from 'src/lib/types/damJSONData';
import damVehicleDataAssetURL, { abstractColors } from './constants';
import fetch from 'cross-fetch';

// gets the data that will be used to update the model name of page
const getVehiclesInformation = async (): Promise<any> => {
  try {
    const hostname = window.location.hostname;
    const response = await fetch('//' + hostname + damVehicleDataAssetURL);
    if (!response.ok) {
      throw new Error(`Failed to load ${'https://' + hostname + damVehicleDataAssetURL} JSON file`);
    }
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error(error);
  }
};

// converts array data to map for search by modelSeries as key
const parseVehicleInformation = (jsonData: any): Record<string, VehicleInformation> => {
  const dynamicStageModelObject: Record<string, VehicleInformation> = {};
  jsonData.models.map((item: any) => {
    dynamicStageModelObject[item.modelSeries] = {
      amgLinePackage: item.amgLinePackage,
      model: item.model,
      modelName: item.modelName,
      buildLink: item.buildLink,
      viewInventoryLink: item.viewInventoryLink,
    };
    return;
  });

  return dynamicStageModelObject;
};

const getAbstractColor = (selectedColor: any): string => {
  const subject = abstractColors.find((abstractColor) => {
    return selectedColor?.toLowerCase().includes(abstractColor.name);
  });
  if (subject) return subject.abstract;
  else return 'OTR';
};

export { getVehiclesInformation, parseVehicleInformation, getAbstractColor };
