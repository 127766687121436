import { dispatchTrackingEvent } from '../utils/tracking.util';
import { ComputedRef, inject, InjectionKey, Ref, unref } from 'vue';

type TrackingFn = (
  eventType: string,
  label: string,
  category: 'feature' | 'link' | 'impression',
  url?: string,
  additionalData?: any,
) => Promise<void>;

export const KEY_TRACKING_ACTION = Symbol() as InjectionKey<Ref<string> | string>;
export const KEY_VEHICLE_TRACKING_DATA = Symbol() as InjectionKey<
  ComputedRef<{ modelCode: string; modelName: string; vehicleId: string; subBrand?: string } | undefined>
>;

export const useTracking = (action?: Ref<string> | string) => {
  // cannot inject when composable is used on the same component level as the provide.
  const trackingAction = action ?? inject(KEY_TRACKING_ACTION, '');

  const createTrackLinkClickFn = (label: string, newTab?: false) => async (e: PointerEvent | MouseEvent) => {
    if (!(e.target instanceof HTMLAnchorElement)) return;

    // open link in new tab when CTRL key (windows) or Command key (Mac) is pressed
    // or when link is clicked with middle mouse btn.
    const windowTarget = newTab || e.ctrlKey || e.metaKey || e.button === 1 ? '_blank' : '_self';

    // get event target before preventDefault, because it's lost after it.
    const targetHref = e.target.href;

    // prevent default behavior of anchor click, so that we can wait for the async tracking before leaving the page.
    e.preventDefault();

    window.open(targetHref, windowTarget);

    await dispatchTrackingEvent('click', label, 'link', unref(trackingAction), targetHref);
  };

  const track: TrackingFn = (eventType, label, category, url, additionalData) =>
    dispatchTrackingEvent(eventType, label, category, unref(trackingAction), url, additionalData);

  const createTrackOnceFn = (...a: Parameters<TrackingFn>) => {
    let tracked = false;
    return () => {
      if (tracked) return;
      tracked = true;
      return track(...a);
    };
  };

  return {
    createTrackLinkClickFn,
    track,
    createTrackOnceFn,
  };
};
