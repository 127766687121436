<script lang="ts" setup>
import type { Style } from '../../lib/api';
import type { SwitcherKind } from '../../lib/types/switchers';
import { isSmallVersion } from '../../composables/isSmallVersion';

// components
import StyleSwitcherSlider from './StyleSwitcherSlider.vue';
import StyleSwitcherButtons from './StyleSwitcherButtons.vue';
import { KEY_VEHICLE_TRACKING_DATA, useTracking } from '@dh-io-owpi/shared/src/composables/useTracking';
import { inject, onMounted, watch } from 'vue';

const props = defineProps<{
  styles: Style[];
  selectedStyle?: Style;
  isDark: boolean;
  isExpanded: boolean;
}>();
const emit = defineEmits<{
  (event: 'switch', style: Style): void;
  (event: 'expand', kind: SwitcherKind): void;
}>();

const vehicleTrackingData = inject(KEY_VEHICLE_TRACKING_DATA)!;

const { track } = useTracking('dynamic_stage|style_switcher');

onMounted(() => {
  // track impression event when style switcher is displayed and tracking data is available
  const unwatch = watch(vehicleTrackingData, () => {
    track('click', '', 'impression', undefined, {
      vehicle: vehicleTrackingData.value,
    });
    unwatch();
  });
});

const previouslySelectedStyles: string[] = [];

const isSelected = (style: Style) => style.id === props.selectedStyle?.id;

const getStylePosition = (style: Style): number => {
  return props.styles.findIndex((s) => s.id === style.id) + 1;
};

const switchStyle = (style: Style) => {
  if (!isSelected(style)) emit('switch', style);

  // only track style selection once
  if (previouslySelectedStyles.includes(style.id)) return;

  track('click', `${getStylePosition(style)}|${style.name}.${style.id}`, 'feature', undefined, {
    vehicle: vehicleTrackingData.value,
  });
  previouslySelectedStyles.push(style.id);
};
</script>
<template>
  <div class="owpi-mbusa-style-switcher">
    <StyleSwitcherSlider
      v-if="isSmallVersion"
      class="owpi-mbusa-style-switcher__slider"
      v-bind="$props"
      :switchStyle="switchStyle"
    />
    <StyleSwitcherButtons
      v-else
      class="owpi-mbusa-style-switcher__buttons"
      v-bind="$props"
      :switchStyle="switchStyle"
      :isSelected="isSelected"
      @expand="emit('expand', 'style')"
    />
  </div>
</template>

<style lang="scss">
.owpi-mbusa-style-switcher {
  @include grid();
  justify-items: center;

  &__slider {
    @include grid-cell(12, 1);
    justify-self: stretch;
  }
  &__buttons {
    @include grid-cell(8, 1);

    @include breakpoint-to(mq4) {
      justify-self: start;
    }

    @include breakpoint-from(mq5) {
      @include grid-cell(8, 3);
    }
  }
}
</style>
